<template>
  <b-modal v-model="showModal"
           aria-label=""
           body-class="modal-form"
           modal-class="modal-style-one modal-background-white questionnaire"
           scrollable
           size="lg"
           :title="title"
           @hidden="clear"
           @ok="confirm"
           @shown="shown"
           no-close-on-backdrop
  >

    <QuestionBase
        v-show="custom"
        :errors="errors.products"
        :question="question.products"
        :valid="!!form.products.length">
      <InputRadioMultipleBasic v-model="form.products"
                               :args="inputArgs.products"
                               :options="productOptions"
                               class="radio-options-wide"
                               @removed="clearProductType"/>

    </QuestionBase>

    <QuestionBase
        v-if="form.products.includes('will')"
        :errors="errors.will_type"
        :question="question.will_type"
        :valid="!!form.will_type">
      <InputRadioBasic v-model="form.will_type"
                       :args="inputArgs.will_type"
                       :options="sendInstructions ? willTypeNoPricesOptions : willTypeOptions"
                       class="radio-options-wide"/>
    </QuestionBase>

    <QuestionBase
        v-if="form.products.includes('lpa')"
        :errors="errors.lpa_type"
        :question="question.lpa_type"
        :valid="!!form.lpa_type">
      <InputRadioBasic v-model="form.lpa_type"
                       :args="inputArgs.lpa_type"
                       :options="lpaTypeOptions"
                       class="radio-options-wide"/>
    </QuestionBase>

    <QuestionBase
        v-if="form.products.includes('estate_planning')"
        :errors="errors.estate_planning_type"
        :question="question.estate_planning_type"
        :valid="!!form.estate_planning_type">
      <InputRadioBasic v-model="form.estate_planning_type"
                       :args="inputArgs.estate_planning_type"
                       :options="reviewTypeOptions"
                       class="radio-options-wide"/>
    </QuestionBase>

    <QuestionBase
        v-if="form.products.includes('property_trust')"
        :errors="errors.property_trust_type"
        :question="question.property_trust_type"
        :valid="!!form.property_trust_type">
      <InputRadioBasic v-model="form.property_trust_type"
                       :args="inputArgs.property_trust_type"
                       :options="propertyTrustTypeOptions"
                       class="radio-options-wide"/>
    </QuestionBase>

    <div v-if="form.will_type || form.lpa_type || form.estate_planning_type || form.property_trust_type">
      <QuestionBase
          :errors="[...errors.client_one.profileNameFirst,
          ...errors.client_one.profileNameMiddle,
          ...errors.client_one.profileNameLast,
          ...errors.client_one.profileNameTitle
          ]"
          :question="question.client_one.fullName"
          :valid="!!(form.client_one.profileNameFirst && form.client_one.profileNameLast && form.client_one.profileNameTitle)">

        <div class="question-multipart-wrapper d-flex flex-wrap align-items-end">
          <div class="question question-multipart field-19">
            <InputSelectBasic v-model="form.client_one.profileNameTitle"
                              :args="inputArgs.client_one.title"
                              :errors="errors.client_one.profileNameTitle"
                              :options="titleOptionsMaleOrFemaleOnly"/>
          </div>

          <div class="question question-multipart field-27">
            <InputStandardBasic v-model="form.client_one.profileNameFirst"
                                :args="inputArgs.client_one.firstName"
                                :errors="errors.client_one.profileNameFirst"/>
          </div>

          <div class="question question-multipart field-27">
            <InputStandardBasic v-model="form.client_one.profileNameMiddle"
                                :args="inputArgs.client_one.middleName"
                                :errors="errors.client_one.profileNameMiddle"/>
          </div>

          <div class="question question-multipart field-27">
            <InputStandardBasic v-model="form.client_one.profileNameLast"
                                :args="inputArgs.client_one.lastName"
                                :errors="errors.client_one.profileNameLast"/>
          </div>

        </div>
      </QuestionBase>

      <template
          v-if="[form.will_type, form.lpa_type, form.estate_planning_type, form.property_trust_type].includes('mirror')">
        <QuestionBase
            :errors="[...errors.client_two.profileNameFirst, ...errors.client_two.profileNameMiddle, ...errors.client_two.profileNameLast, ...errors.client_two.profileNameTitle]"
            :question="question.client_two.fullName"
            :valid="!!(form.client_two.profileNameFirst && form.client_two.profileNameLast && form.client_two.profileNameTitle)">

          <div class="question-multipart-wrapper d-flex flex-wrap align-items-end">
            <div class="question question-multipart field-19">
              <InputSelectBasic v-model="form.client_two.profileNameTitle"
                                :args="inputArgs.client_two.title"
                                :errors="errors.client_two.profileNameTitle"
                                :options="titleOptionsMaleOrFemaleOnly"/>
            </div>

            <div class="question question-multipart field-27">
              <InputStandardBasic v-model="form.client_two.profileNameFirst"
                                  :args="inputArgs.client_two.firstName"
                                  :errors="errors.client_two.profileNameFirst"/>
            </div>

            <div class="question question-multipart field-27">
              <InputStandardBasic v-model="form.client_two.profileNameMiddle"
                                  :args="inputArgs.client_two.middleName"
                                  :errors="errors.client_two.profileNameMiddle"/>
            </div>

            <div class="question question-multipart field-27">
              <InputStandardBasic v-model="form.client_two.profileNameLast"
                                  :args="inputArgs.client_two.lastName"
                                  :errors="errors.client_two.profileNameLast"/>
            </div>
          </div>
        </QuestionBase>
      </template>

      <QuestionBase
          v-if="![form.will_type, form.lpa_type, form.estate_planning_type, form.property_trust_type].includes('mirror')"
          :errors="[...errors.client_one.profileEmail, ...errors.client_two.profileEmail]"
          :question="question.email"
          :valid="!!(form.client_one.profileEmail && form.client_two.profileEmail)">
        <InputStandardBasic v-model="form.client_one.profileEmail"
                            :args="inputArgs.client_one.email"
                            :errors="errors.client_one.profileEmail"/>
      </QuestionBase>

      <QuestionBase
          v-if="[form.will_type, form.lpa_type, form.estate_planning_type, form.property_trust_type].includes('mirror')"
          :errors="[...errors.client_one.profileEmail, ...errors.client_two.profileEmail]"
          :question="question.emailJoint"
          :valid="!!(form.client_one.profileEmail && form.client_two.profileEmail)">
        <div class="question-multipart-wrapper d-flex flex-wrap align-items-end">
          <div
              class="question question-multipart field-50">
            <InputStandardBasic v-model="form.client_one.profileEmail"
                                :args="inputArgs.client_one.email"
                                :errors="errors.client_one.profileEmail"/>
          </div>


          <div
              class="question question-multipart field-50">
            <InputStandardBasic v-model="form.client_two.profileEmail"
                                :args="inputArgs.client_two.email"
                                :errors="errors.client_two.profileEmail"/>
          </div>
        </div>
      </QuestionBase>

      <QuestionBase
          v-if="![form.will_type, form.lpa_type, form.estate_planning_type, form.property_trust_type].includes('mirror')"
          :errors="[...errors.client_one.profileMobileNumber, ...errors.client_two.profileMobileNumber]"
          :question="question.phone"
          :valid="!!(form.client_one.profileMobileNumber && form.client_two.profileMobileNumber)">
        <InputStandardBasic v-model="form.client_one.profileMobileNumber"
                            :args="inputArgs.client_one.phone"
                            :errors="errors.client_one.profileMobileNumber"/>
      </QuestionBase>

      <QuestionBase
          v-if="[form.will_type, form.lpa_type, form.estate_planning_type, form.property_trust_type].includes('mirror')"
          :errors="[...errors.client_one.profileMobileNumber, ...errors.client_two.profileMobileNumber]"
          :question="question.phoneJoint"
          :valid="!!(form.client_one.profileMobileNumber && form.client_two.profileMobileNumber)">

        <div class="question-multipart-wrapper d-flex flex-wrap align-items-end">
          <div
              class="question question-multipart field-50">
            <InputStandardBasic v-model="form.client_one.profileMobileNumber"
                                :args="inputArgs.client_one.phone"
                                :errors="errors.client_one.profileMobileNumber"/>
          </div>


          <div
              class="question question-multipart field-50">
            <InputStandardBasic v-model="form.client_two.profileMobileNumber"
                                :args="inputArgs.client_two.phone"
                                :errors="errors.client_two.profileMobileNumber"/>
          </div>
        </div>
      </QuestionBase>


    </div>

    <QuestionBase v-if="form.products.includes('estate_planning')" :valid="form.handover !== null"
                  :errors="errors.handover" :question="question.handover">
      <InputRadioBasic
          v-model="form.handover"
          :args="inputArgs.handover"
          :options="handoverOptions"
          class="radio-options-wide"
      />
    </QuestionBase>


    <template #modal-footer="{ ok, cancel }">
      <div class="w-100 m-0 d-flex">
        <button
            class="btn btn-backwards white-border"
            @click="cancel">Back
        </button>
        <button
            :disabled="disabled"
            class="btn btn-forwards"
            @click="ok">Next
        </button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import {clone} from "@/mixins/clone";
import {http} from "@/services";
import {estatePlanningOptions} from "@/components/common/other/inputs/estatePlanningOptions";
import {personalDetails} from "@/components/common/questionnaires/question/options/personalDetails";
import {toast} from '@/mixins/toast'

import QuestionBase from "../../../../components/common/questionnaires/question/QuestionBase";
import InputRadioBasic from "../../../../components/common/other/inputs/InputRadioBasic";
import InputStandardBasic from "../../../../components/common/other/inputs/InputStandardBasic";
import InputSelectBasic from "../../../../components/common/other/inputs/InputSelectBasic";
import InputRadioMultipleBasic from "../../../../components/common/other/inputs/InputRadioMultipleBasic";

export default {
  name: 'EstatePlanningModal',
  components: {
    InputRadioMultipleBasic,
    InputSelectBasic,
    InputStandardBasic,
    InputRadioBasic,
    QuestionBase
  },
  mixins: [
    estatePlanningOptions,
    personalDetails,
    clone,
    toast
  ],
  props: {
    value: {
      type: Boolean,
      required: false
    },
    selectedProducts: {
      type: Array,
      default: () => {
        return ['custom']
      }
    },
    prices: {
      type: Object,
      required: true
    },
    // used to send instructions directly to client or introducer goto transaction
    sendInstructions: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    showModal: {
      set(value) {
        this.$emit('input',
            value)
      },
      get() {
        return this.value
      }
    },
    title() {
      if (this.sendInstructions) return 'Send Product Instructions'
      return 'New Estate Planning Referral'
    },
    user() {
      return this.$store.getters.user
    },
    disabled() {
      return false
    }
  },
  watch: {
    'form.client_one.profileNameTitle'() {
      this.setGender('client_one')
    },
    'form.client_two.profileNameTitle'() {
      this.setGender('client_two')
    },
    'form.products'() {
      if (this.form.products.includes('property_trust')) this.form.property_trust_type = 'mirror'
      else this.form.property_trust_type = null
    }
  },
  data() {
    return {
      question: {
        products: {
          title: 'Which estate planning products would you like?',
          subTitle: null,
          tip: null
        },
        handover: {
          title: 'Do you want to enter additional client details?',
          subTitle: 'Add additional client or product specific details.',
          tip: null
        },
        will_type: {
          title: 'Would you like to draft a single will or two mirror wills?',
          subTitle: `Where a single will is suitable for a single person, mirror wills are suitable for couples (even if the couples wishes differ slightly).`,
          tip: null
        },
        lpa_type: {
          title: 'Would you like to draft a single will or two mirror lpa\'s?',
          subTitle: `Where a single lpa is suitable for a single person, mirror lpa's are suitable for couples (even if the couples wishes differ slightly).`,
          tip: null
        },
        estate_planning_type: {
          title: 'Are you dealing with a single person or a couple?',
          subTitle: `If your client is in a relationship but only wishes to complete a review for themselves please select 'Single Person'.`,
          tip: null
        },
        property_trust_type: {
          title: 'Protective Property Trust',
          subTitle: `Generally, couples want the family home to pass to their children. They often leave their assets to each other initially, in the hope that everything will pass to their children in due course.`,
          tip: null
        },
        client_one: {
          title: {
            title: 'What is the client\'s title?'
          },
          fullName: {
            title: 'What is the first client\'s full name?',
            subTitle: 'Enter the clients name as it appears on their birth certificate or other identification.'
          },
        },
        client_two: {
          title: {
            title: 'What is client two\'s title?'
          },
          fullName: {
            title: 'What is the second client\'s full name?',
            subTitle: 'Enter the clients name as it appears on their birth certificate or other identification.'
          },
        },
        email: {
          title: 'What is your client\'s email address?'
        },
        emailJoint: {
          title: 'What are your clients email address\'s?'
        },
        phone: {
          title: 'What is the client\'s mobile number?'
        },
        phoneJoint: {
          title: 'What are the clients mobile number\'s?'
        }
      },
      form: {
        products: [],
        handover: null,
        will_type: null,
        lpa_type: null,
        estate_planning_type: null,
        property_trust_type: null,
        send_instructions: false,
        client_one: {
          profileNameTitle: null,
          profileNameFirst: null,
          profileNameMiddle: null,
          profileNameLast: null,
          profileEmail: null,
          profileMobileNumber: null,
          profileSex: null
        },
        client_two: {
          profileNameTitle: null,
          profileNameFirst: null,
          profileNameMiddle: null,
          profileNameLast: null,
          profileEmail: null,
          profileMobileNumber: null,
          profileSex: null
        }
      },
      errors: {
        products: [],
        handover: [],
        will_type: [],
        lpa_type: [],
        estate_planning_type: [],
        property_trust_type: [],
        client_one: {
          profileNameTitle: [],
          profileNameFirst: [],
          profileNameMiddle: [],
          profileNameLast: [],
          profileEmail: [],
          profileMobileNumber: [],
          profileSex: []
        },
        client_two: {
          profileNameTitle: [],
          profileNameFirst: [],
          profileNameMiddle: [],
          profileNameLast: [],
          profileEmail: [],
          profileMobileNumber: [],
          profileSex: []
        }
      },
      inputArgs: {
        products: {},
        handover: {},
        will_type: {},
        lpa_type: {},
        estate_planning_type: {},
        property_trust_type: {
          disabled: true
        },
        client_one: {
          title: {
            placeholder: 'Select',
            inputClass: 'field-100',
            label: 'Title',
          },
          firstName: {
            placeholder: 'e.g. Joe',
            label: 'First Name'
          },
          middleName: {
            placeholder: 'e.g. Edward',
            label: 'Middle Name'
          },
          lastName: {
            placeholder: 'e.g. Blogs',
            label: 'Last Name'
          },
          email: {
            placeholder: 'e.g. client_one@gmail.com',
            inputClass: 'field-100'
          },
          phone: {
            placeholder: 'e.g. 0790128101',
            inputClass: 'field-100'
          }
        },
        client_two: {
          title: {
            placeholder: 'Select',
            inputClass: 'field-100',
            label: 'Title',
          },
          firstName: {
            placeholder: 'e.g. Jill',
            label: 'First Name'
          },
          middleName: {
            placeholder: 'e.g. Mary',
            label: 'Middle Name'
          },
          lastName: {
            placeholder: 'e.g. Blogs',
            label: 'Last Name'
          },
          email: {
            placeholder: 'e.g. client_two@gmail.com',
            inputClass: 'field-100'
          },
          phone: {
            placeholder: 'e.g. 0790128101',
            inputClass: 'field-100'
          }
        }
      },
      // custom mode allows multiple products to be selected
      custom: false
    }
  },
  methods: {
    confirm(bvEvent) {
      bvEvent.preventDefault()
      this.$bvModal
          .msgBoxConfirm(
              `By proceeding the clients details will be passed to Dunham McCarthy, please ensure your client is aware of this, or you have the clients permission to share their details.`,
              {
                title: 'Please Confirm',
                size: 'md',
                buttonSize: 'sm',
                cancelVariant: 'outline-primary',
                okVariant: 'secondary',
                okTitle: 'Confirm',
                cancelTitle: 'Cancel',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
              }
          )
          .then((value) => {
            if (value) this.ok(bvEvent);
          })
          .catch((err) => {
            console.log(err);
          });
    },
    ok(bvEvent) {
      bvEvent.preventDefault()
      this.clearErrors()
      let data = this.clone(this.form)
      // if single will remove client two from post data
      if (this.form.will_type === 'single' && this.form.lpa_type === 'single') delete data.client_two

      http.post('create_user_and_estate_planning', data).then(response => {
        if (this.form.send_instructions) {
          this.toast('Instructions sent')
          this.showModal = false
          this.$emit('refresh') // refresh recent transactions
          return
        }
        // goto transaction detail
        if (this.form.handover) {
          this.handoverTransaction(response.data.transaction)
        } else if ('transaction' in response.data) {
          this.gotoTransaction(response.data.transaction)
        }
      }).catch(error => {
        console.log('error', error)
        this.handleErrors(error.response.data)
      })

    },
    clear() {
      this.custom = false
      this.clearErrors()
      this.clearForm()
    },
    shown() {
      // set form product type (pre selected)
      // allow multiple select
      if (this.selectedProducts.includes('custom')) {
        this.custom = true
        return
      }
      // add pre required products to form
      for (let i in this.selectedProducts) {
        this.form.products.push(this.selectedProducts[i])
      }
      // send instructions
      this.form.send_instructions = this.sendInstructions
    },
    handleErrors(errors) {
      // assign errors to inputs
      let keys = Object.keys(errors);

      for (let i in keys) {
        if (keys[i] === 'client_one') {
          let clientOneKeys = Object.keys(errors.client_one)
          for (let i in clientOneKeys) {
            this.errors.client_one[clientOneKeys[i]] = errors.client_one[clientOneKeys[i]]
            this.toastError(`${errors.client_one[clientOneKeys[i]][0]}`)
          }

        } else if (keys[i] === 'client_two') {
          let clientTwoKeys = Object.keys(errors.client_two)
          for (let i in clientTwoKeys) {
            this.errors.client_two[clientTwoKeys[i]] = errors.client_two[clientTwoKeys[i]]
            this.toastError(`${errors.client_two[clientTwoKeys[i]][0]}`)
          }
        } else {
          this.errors[keys[i]] = errors[keys[i]]
        }
      }
    },
    setGender(client) {
      // sets client gender based upon title
      if (['Mr'].includes(this.form[client].profileNameTitle)) {
        this.form[client].profileSex = 'male'
      } else if ([
        'Mrs',
        'Ms',
        'Miss'
      ].includes(this.form[client].profileNameTitle)) {
        this.form[client].profileSex = 'female'
      }
    },
    clearProductType(type) {
      // clears type properties on product input
      if (type === 'will') this.form.will_type = null
      else if (type === 'lpa') this.form.lpa_type = null
      else if (type === 'estate_planning') this.form.estate_planning_type = null
      else if (type === 'property_trust') this.form.property_trust_type = null
    },
    clearForm() {
      this.form.products = []
      this.form.handover = null
      this.form.will_type = null
      this.form.lpa_type = null
      this.form.send_instructions = false
      this.form.property_trust_type = null
      this.form.estate_planning_type = null
      this.form.client_one.profileNameTitle = null
      this.form.client_one.profileNameFirst = null
      this.form.client_one.profileNameMiddle = null
      this.form.client_one.profileNameLast = null
      this.form.client_one.profileEmail = null
      this.form.client_one.profileSex = null
      this.form.client_one.profileMobileNumber = null
      this.form.client_two.profileNameTitle = null
      this.form.client_two.profileNameFirst = null
      this.form.client_two.profileNameMiddle = null
      this.form.client_two.profileNameLast = null
      this.form.client_two.profileEmail = null
      this.form.client_two.profileSex = null
      this.form.client_two.profileMobileNumber = null

    },
    clearErrors() {
      this.errors.products = []
      this.errors.handover = []
      this.errors.will_type = []
      this.errors.lpa_type = []
      this.errors.property_trust_type = []
      this.errors.client_one.profileNameTitle = []
      this.errors.client_one.profileNameFirst = []
      this.errors.client_one.profileNameMiddle = []
      this.errors.client_one.profileNameLast = []
      this.errors.client_one.profileEmail = []
      this.errors.client_one.profileSex = []
      this.errors.client_one.profileMobileNumber = []
      this.errors.client_two.profileNameTitle = []
      this.errors.client_two.profileNameFirst = []
      this.errors.client_two.profileNameMiddle = []
      this.errors.client_two.profileNameLast = []
      this.errors.client_two.profileEmail = []
      this.errors.client_two.profileSex = []
      this.errors.client_two.profileMobileNumber = []
    },
    gotoTransaction(transaction) {
      this.$router.push({
        name: 'IntroducerTransactionDetail',
        params: {id: transaction.id}
      })
    },
    handoverTransaction(transaction) {
      let data = {
        id: transaction.id,
        handover_point: 'before_client_profile',
        handover_point_by: this.user.id,
        handover_point_date: new Date()
      }
      http.patch('transaction_handover', data).then(
          () => {
            this.toast('Client successfully referred', 'Success')
            this.showModal = false
            this.$emit('refreshHandedOver', transaction)
          }
      ).catch(
          error => {
            console.log(error)
          }
      )
    }
  }
}

</script>
