import { render, staticRenderFns } from "./InProgressTransactions.vue?vue&type=template&id=47ace119"
import script from "./InProgressTransactions.vue?vue&type=script&lang=js"
export * from "./InProgressTransactions.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports