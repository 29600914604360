<template>
  <b-modal v-model="showModal"
           aria-label=""
           body-class="modal-form"
           modal-class="modal-style-one modal-background-white questionnaire"
           scrollable
           size="lg"
           :title="title"
           @hidden="clear"
           @ok="confirm"
           no-close-on-backdrop
  >

    <QuestionBase
        :errors="errors.insurance_type"
        :question="question.insurance_type"
        :valid="!!form.insurance_type">
      <InputRadioBasic v-model="form.insurance_type"
                       :args="inputArgs.insurance_type"
                       :options="reviewTypeOptions"
                       class="radio-options-wide"/>
    </QuestionBase>

    <div v-if="form.insurance_type">
      <QuestionBase
          :errors="[...errors.client_one.profileNameFirst,
          ...errors.client_one.profileNameMiddle,
          ...errors.client_one.profileNameLast,
          ...errors.client_one.profileNameTitle
          ]"
          :question="question.client_one.fullName"
          :valid="!!(form.client_one.profileNameFirst && form.client_one.profileNameLast && form.client_one.profileNameTitle)">

        <div class="question-multipart-wrapper d-flex flex-wrap align-items-end">
          <div class="question question-multipart field-19">
            <InputSelectBasic v-model="form.client_one.profileNameTitle"
                              :args="inputArgs.client_one.title"
                              :errors="errors.client_one.profileNameTitle"
                              :options="titleOptions"/>
          </div>

          <div class="question question-multipart field-27">
            <InputStandardBasic v-model="form.client_one.profileNameFirst"
                                :args="inputArgs.client_one.firstName"
                                :errors="errors.client_one.profileNameFirst"/>
          </div>

          <div class="question question-multipart field-27">
            <InputStandardBasic v-model="form.client_one.profileNameMiddle"
                                :args="inputArgs.client_one.middleName"
                                :errors="errors.client_one.profileNameMiddle"/>
          </div>

          <div class="question question-multipart field-27">
            <InputStandardBasic v-model="form.client_one.profileNameLast"
                                :args="inputArgs.client_one.lastName"
                                :errors="errors.client_one.profileNameLast"/>
          </div>
        </div>
      </QuestionBase>

      <template
          v-if="[form.insurance_type].includes('mirror')">
        <QuestionBase
            :errors="[...errors.client_two.profileNameFirst, ...errors.client_two.profileNameMiddle, ...errors.client_two.profileNameLast, ...errors.client_two.profileNameTitle]"
            :question="question.client_two.fullName"
            :valid="!!(form.client_two.profileNameFirst && form.client_two.profileNameLast && form.client_two.profileNameTitle)">

          <div class="question-multipart-wrapper d-flex flex-wrap align-items-end">
            <div class="question question-multipart field-19">
              <InputSelectBasic v-model="form.client_two.profileNameTitle"
                                :args="inputArgs.client_two.title"
                                :errors="errors.client_two.profileNameTitle"
                                :options="titleOptions"/>
            </div>

            <div class="question question-multipart field-27">
              <InputStandardBasic v-model="form.client_two.profileNameFirst"
                                  :args="inputArgs.client_two.firstName"
                                  :errors="errors.client_two.profileNameFirst"/>
            </div>

            <div class="question question-multipart field-27">
              <InputStandardBasic v-model="form.client_two.profileNameMiddle"
                                  :args="inputArgs.client_two.middleName"
                                  :errors="errors.client_two.profileNameMiddle"/>
            </div>

            <div class="question question-multipart field-27">
              <InputStandardBasic v-model="form.client_two.profileNameLast"
                                  :args="inputArgs.client_two.lastName"
                                  :errors="errors.client_two.profileNameLast"/>
            </div>
          </div>
        </QuestionBase>
      </template>

      <QuestionBase
          v-if="![form.insurance_type].includes('mirror')"
          :errors="[...errors.client_one.profileEmail, ...errors.client_two.profileEmail]"
          :question="question.email"
          :valid="!!(form.client_one.profileEmail && form.client_two.profileEmail)">
        <InputStandardBasic v-model="form.client_one.profileEmail"
                            :args="inputArgs.client_one.email"
                            :errors="errors.client_one.profileEmail"/>
      </QuestionBase>

      <QuestionBase
          v-if="[form.insurance_type].includes('mirror')"
          :errors="[...errors.client_one.profileEmail, ...errors.client_two.profileEmail]"
          :question="question.emailJoint"
          :valid="!!(form.client_one.profileEmail && form.client_two.profileEmail)">

        <div class="question-multipart-wrapper d-flex flex-wrap align-items-end">
          <div
              class="question question-multipart field-50">
            <InputStandardBasic v-model="form.client_one.profileEmail"
                                :args="inputArgs.client_one.email"
                                :errors="errors.client_one.profileEmail"/>
          </div>


          <div
              class="question question-multipart field-50">
            <InputStandardBasic v-model="form.client_two.profileEmail"
                                :args="inputArgs.client_two.email"
                                :errors="errors.client_two.profileEmail"/>
          </div>
        </div>
      </QuestionBase>

      <QuestionBase
          v-if="![form.insurance_type].includes('mirror')"
          :errors="[...errors.client_one.profileMobileNumber, ...errors.client_two.profileMobileNumber]"
          :question="question.phone"
          :valid="!!(form.client_one.profileMobileNumber && form.client_two.profileMobileNumber)">
        <InputStandardBasic v-model="form.client_one.profileMobileNumber"
                            :args="inputArgs.client_one.phone"
                            :errors="errors.client_one.profileMobileNumber"/>
      </QuestionBase>

      <QuestionBase
          v-if="[form.insurance_type].includes('mirror')"
          :errors="[...errors.client_one.profileMobileNumber, ...errors.client_two.profileMobileNumber]"
          :question="question.phoneJoint"
          :valid="!!(form.client_one.profileMobileNumber && form.client_two.profileMobileNumber)">

        <div class="question-multipart-wrapper d-flex flex-wrap align-items-end">
          <div
              class="question question-multipart field-50">
            <InputStandardBasic v-model="form.client_one.profileMobileNumber"
                                :args="inputArgs.client_one.phone"
                                :errors="errors.client_one.profileMobileNumber"/>
          </div>


          <div
              class="question question-multipart field-50">
            <InputStandardBasic v-model="form.client_two.profileMobileNumber"
                                :args="inputArgs.client_two.phone"
                                :errors="errors.client_two.profileMobileNumber"/>
          </div>
        </div>
      </QuestionBase>


    </div>

    <QuestionBase :valid="form.handover !== null" :errors="errors.handover" :question="question.handover">
      <InputRadioBasic
          v-model="form.handover"
          :args="inputArgs.handover"
          :options="handoverOptions"
          class="radio-options-wide"
      />
    </QuestionBase>


    <template #modal-footer="{ ok, cancel }">
      <div class="w-100 m-0 d-flex">
        <button
            class="btn btn-backwards white-border"
            @click="cancel">Back
        </button>
        <button
            :disabled="disabled"
            class="btn btn-forwards"
            @click="ok">Next
        </button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import {clone} from "@/mixins/clone";
import {http} from "@/services";
import {insuranceOptions} from "@/components/common/other/inputs/insuranceOptions";
import {personalDetails} from "@/components/common/questionnaires/question/options/personalDetails";
import {toast} from '@/mixins/toast'

import QuestionBase from "../../../../components/common/questionnaires/question/QuestionBase";
import InputRadioBasic from "../../../../components/common/other/inputs/InputRadioBasic";
import InputStandardBasic from "../../../../components/common/other/inputs/InputStandardBasic";
import InputSelectBasic from "../../../../components/common/other/inputs/InputSelectBasic";

export default {
  name: 'InsuranceModal',
  components: {
    InputSelectBasic,
    InputStandardBasic,
    InputRadioBasic,
    QuestionBase
  },
  mixins: [
    insuranceOptions,
    personalDetails,
    clone,
    toast
  ],
  props: {
    value: {
      type: Boolean,
      required: false
    },
    prices: {
      type: Object,
      required: true
    }
  },
  computed: {
    showModal: {
      set(value) {
        this.$emit('input',
            value)
      },
      get() {
        return this.value
      }
    },
    title() {
      if (this.form.handover) return 'Refer Client for Insurance Advice'
      return 'New Insurance Referral'
    },
    user() {
      return this.$store.getters.user
    },
    disabled() {
      return this.form.handover === null || this.form.insurance_type === null
    }
  },
  watch: {
    'form.client_one.profileNameTitle'() {
      this.setGender('client_one')
    },
    'form.client_two.profileNameTitle'() {
      this.setGender('client_two')
    }
  },
  data() {
    return {
      question: {
        insurance_type: {
          title: 'Are you dealing with a single person or a couple?',
          subTitle: `If your client is in a relationship but only wishes to complete a review for themselves please select 'Single Person'.`,
          tip: null
        },
        handover: {
          title: 'Do you want to enter additional client details?',
          subTitle: 'Add additional client or product specific details.',
          tip: null
        },
        client_one: {
          title: {
            title: 'What is the first applicant\'s title?'
          },
          fullName: {
            title: 'What is the first applicant\'s full name?',
            subTitle: 'Enter the clients name as it appears on their birth certificate or other identification.'
          },
        },
        client_two: {
          title: {
            title: 'What is applicant two\'s title?'
          },
          fullName: {
            title: 'What is the second applicant\'s full name?',
            subTitle: 'Enter the clients name as it appears on their birth certificate or other identification.'
          },
        },
        email: {
          title: 'What is the applicant\'s email address?'
        },
        emailJoint: {
          title: 'What are the applicants email address\'s?'
        },
        phone: {
          title: 'What is the applicant\'s mobile number?'
        },
        phoneJoint: {
          title: 'What are the applicants mobile number\'s?'
        }
      },
      form: {
        insurance_type: null,
        handover: null,
        client_one: {
          profileNameTitle: null,
          profileNameFirst: null,
          profileNameMiddle: null,
          profileNameLast: null,
          profileEmail: null,
          profileMobileNumber: null,
          profileSex: null
        },
        client_two: {
          profileNameTitle: null,
          profileNameFirst: null,
          profileNameMiddle: null,
          profileNameLast: null,
          profileEmail: null,
          profileMobileNumber: null,
          profileSex: null
        }
      },
      errors: {
        insurance_type: [],
        handover: [],
        client_one: {
          profileNameTitle: [],
          profileNameFirst: [],
          profileNameMiddle: [],
          profileNameLast: [],
          profileEmail: [],
          profileMobileNumber: [],
          profileSex: []
        },
        client_two: {
          profileNameTitle: [],
          profileNameFirst: [],
          profileNameMiddle: [],
          profileNameLast: [],
          profileEmail: [],
          profileMobileNumber: [],
          profileSex: []
        }
      },
      inputArgs: {
        insurance_type: {},
        handover: {},
        client_one: {
          title: {
            placeholder: 'Select',
            inputClass: 'field-100',
            label: 'Title',
          },
          firstName: {
            placeholder: 'e.g. Joe',
            label: 'First Name'
          },
          middleName: {
            placeholder: 'e.g. Edward',
            label: 'Middle Name'
          },
          lastName: {
            placeholder: 'e.g. Blogs',
            label: 'Last Name'
          },
          email: {
            placeholder: 'e.g. client_one@gmail.com',
            inputClass: 'field-100'
          },
          phone: {
            placeholder: 'e.g. 0790128101',
            inputClass: 'field-100'
          }
        },
        client_two: {
          title: {
            placeholder: 'Select',
            inputClass: 'field-100',
            label: 'Title',
          },
          firstName: {
            placeholder: 'e.g. Jill',
            label: 'First Name'
          },
          middleName: {
            placeholder: 'e.g. Mary',
            label: 'Middle Name'
          },
          lastName: {
            placeholder: 'e.g. Blogs',
            label: 'Last Name'
          },
          email: {
            placeholder: 'e.g. client_two@gmail.com',
            inputClass: 'field-100'
          },
          phone: {
            placeholder: 'e.g. 0790128101',
            inputClass: 'field-100'
          }
        }
      }
    }
  },
  methods: {
    confirm(bvEvent) {
      bvEvent.preventDefault()
      this.$bvModal
          .msgBoxConfirm(
              `By proceeding the clients details will be passed to Dunham McCarthy, please ensure your ` +
              `client is aware of this, or you have the clients permission to share their details.`,
              {
                title: 'Please Confirm',
                size: 'md',
                buttonSize: 'sm',
                cancelVariant: 'outline-primary',
                okVariant: 'secondary',
                okTitle: 'Confirm',
                cancelTitle: 'Cancel',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
              }
          )
          .then((value) => {
            if (value) this.ok(bvEvent);
          })
          .catch((err) => {
            console.log(err);
          });
    },
    ok(bvEvent) {
      bvEvent.preventDefault()
      this.clearErrors()
      let data = this.clone(this.form)

      http.post('create_user_and_insurance', data).then(response => {
        // goto transaction detail
        if (this.form.handover) {
          this.handoverTransaction(response.data.transaction)
        } else if ('transaction' in response.data) {
          this.gotoTransaction(response.data.transaction)
        }
      }).catch(error => {
        this.handleErrors(error.response.data)
      })

    },
    clear() {
      this.custom = false
      this.clearErrors()
      this.clearForm()
    },

    handleErrors(errors) {
      // assign errors to inputs
      let keys = Object.keys(errors);

      for (let i in keys) {
        if (keys[i] === 'client_one') {
          let client_oneKeys = Object.keys(errors.client_one)
          for (let i in client_oneKeys) {
            this.errors.client_one[client_oneKeys[i]] = errors.client_one[client_oneKeys[i]]
            this.toastError(`${errors.client_one[client_oneKeys[i]][0]}`)

          }
        } else if (keys[i] === 'client_two') {
          let client_twoKeys = Object.keys(errors.client_two)
          for (let i in client_twoKeys) {
            this.errors.client_two[client_twoKeys[i]] = errors.client_two[client_twoKeys[i]]
            this.toastError(`${errors.client_two[client_twoKeys[i]][0]}`)

          }
        } else {
          this.errors[keys[i]] = errors[keys[i]]
        }
      }
    },
    setGender(client) {
      // sets client gender based upon title
      if (['Mr'].includes(this.form[client].profileNameTitle)) {
        this.form[client].profileSex = 'male'
      } else if ([
        'Mrs',
        'Ms',
        'Miss'
      ].includes(this.form[client].profileNameTitle)) {
        this.form[client].profileSex = 'female'
      }
    },
    clearForm() {
      this.form.insurance_type = null
      this.form.handover = null
      this.form.client_one.profileNameTitle = null
      this.form.client_one.profileNameFirst = null
      this.form.client_one.profileNameMiddle = null
      this.form.client_one.profileNameLast = null
      this.form.client_one.profileEmail = null
      this.form.client_one.profileSex = null
      this.form.client_one.profileMobileNumber = null
      this.form.client_two.profileNameTitle = null
      this.form.client_two.profileNameFirst = null
      this.form.client_two.profileNameMiddle = null
      this.form.client_two.profileNameLast = null
      this.form.client_two.profileEmail = null
      this.form.client_two.profileSex = null
      this.form.client_two.profileMobileNumber = null
    },
    clearErrors() {
      this.errors.handover = []
      this.errors.client_one.profileNameTitle = []
      this.errors.client_one.profileNameFirst = []
      this.errors.client_one.profileNameMiddle = []
      this.errors.client_one.profileNameLast = []
      this.errors.client_one.profileEmail = []
      this.errors.client_one.profileSex = []
      this.errors.client_one.profileMobileNumber = []
      this.errors.client_two.profileNameTitle = []
      this.errors.client_two.profileNameFirst = []
      this.errors.client_two.profileNameMiddle = []
      this.errors.client_two.profileNameLast = []
      this.errors.client_two.profileEmail = []
      this.errors.client_two.profileSex = []
      this.errors.client_two.profileMobileNumber = []
    },
    gotoTransaction(transaction) {
      this.$router.push({
        name: 'IntroducerTransactionDetail',
        params: {id: transaction.id}
      })
    },
    handoverTransaction(transaction) {
      let data = {
        id: transaction.id,
        handover_point: 'before_client_profile',
        handover_point_by: this.user.id,
        handover_point_date: new Date()
      }
      http.patch('transaction_handover', data).then(
          () => {
            this.toast('Client successfully referred', 'Success')
            this.showModal = false
            this.$emit('refreshHandedOver', transaction)
          }
      ).catch(
          error => {
            console.log(error)
          }
      )
    }
  }
}

</script>
