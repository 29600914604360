<template>
  <div>
    <div class="section-title">
      <h4 class="p-3 pl-30 mb-2">
        <span>Estate Planning</span>
      </h4>
      <a v-b-toggle.BtnEP class="d-block">
        <i class="i-Arrow-Down-2 cursor-pointer text-25 t-font-boldest text-secondary"></i>
      </a>
      <a class="d-block">
        <i class="i-Information cursor-pointer text-25 text-secondary"></i>
      </a>
    </div>

    <b-collapse visible id="BtnEP">
      <b-row class="pl-30 pl-md-48-md pr-20 pr-md-0 mb-30">
        <b-col md="9">
          <p>The talented team of estate planners at Dunham McCarthy have helped more than 50,000 clients put their
            affairs in order. In addition to drafting wills and trusts they have decades of experience dealing with
            deputyship, Inheritance Tax and power of attorney, so you can rest assured that your clients are in safe
            hands.</p>
        </b-col>
      </b-row>
      <b-row class="pl-30 pl-md-48-md pr-20 pr-md-0">
        <b-col md="3">
          <b-card class="bg-primary hover-down dash-btn white"
                  @click="setProductModal(['will'])">
            <img alt="shield"
                 class="btn-background-img"
                 src="@/assets/images/start-1.png">
            <div class="face">
              <div class="face-top">
                <h3>Last Will & Testament</h3>
                <h6>Single or Mirror</h6>
              </div>
              <div class="face-bottom">
                <i class="i-Assistant"/>
                <span>{{ currency(estatePlanningPrices.singleWill) }}</span>
                <i class="i-Business-ManWoman"/>
                <span>{{ currency(estatePlanningPrices.mirrorWill) }}</span>
              </div>
            </div>
            <div class="back">
              <p>Quickly and easily organise a comprehensive Will. Suitable for those in England / Wales only.
              </p>
              <h3>Click to Begin</h3>
            </div>
          </b-card>
        </b-col>

        <!--        <b-col md="3">-->
        <!--          <b-card class="bg-primary hover-down dash-btn white"-->
        <!--                  @click="setProductModal(['lpa'])">-->
        <!--            <img alt="shield"-->
        <!--                 class="btn-background-img"-->
        <!--                 src="@/assets/images/start-3.png">-->
        <!--            <div class="face">-->
        <!--              <div class="face-top">-->
        <!--                <h3>Mirror Wills & Property Trusts</h3>-->
        <!--                <h6>Couples Only</h6>-->
        <!--              </div>-->
        <!--              <div class="face-bottom">-->
        <!--                <i class="i-Business-ManWoman"/>-->
        <!--                <span>{{ currency(estatePlanningPrices.mirrorLpa) }}</span>-->
        <!--              </div>-->
        <!--            </div>-->
        <!--            <div class="back">-->
        <!--              <p>Organise a Will and protect the family home in the process. Suitable for those in England / Wales only.-->
        <!--              </p>-->
        <!--              <h3>Click to Begin</h3>-->
        <!--            </div>-->
        <!--          </b-card>-->
        <!--        </b-col>-->

        <b-col md="3">
          <b-card class="hover-down dash-btn white"
                  style="background: #533d54"
                  @click="setProductModal(['lpa'])">
            <img alt="shield"
                 class="btn-background-img"
                 src="@/assets/images/start-2.png">
            <div class="face">
              <div class="face-top">
                <h3>Lasting Power of Attorney</h3>
                <h6>Health & Financial</h6>
              </div>
              <div class="face-bottom">
                <i class="i-Assistant"/>
                <span>{{ currency(estatePlanningPrices.singleLpa) }}</span>
                <i class="i-Business-ManWoman"/>
                <span>{{ currency(estatePlanningPrices.mirrorLpa) }}</span>
              </div>
            </div>
            <div class="back">
              <p>Organise an LPA to help protect your client against the myriad of issues associated with illness and
                incapacity.
              </p>
              <h3>Click to Begin</h3>
            </div>
          </b-card>
        </b-col>

        <b-col md="3">
          <b-card class="hover-down dash-btn white"
                  style="background: #321433"
                  @click="setProductModal(['lpa'])">
            <img alt="shield"
                 class="btn-background-img"
                 src="@/assets/images/start-3.png">
            <div class="face">
              <div class="face-top">
                <h3>Home Protection Trust</h3>
                <h6>Lifetime Planning</h6>
              </div>
              <div class="face-bottom">
                <i class="i-Assistant"/>
                <span>{{ currency(estatePlanningPrices.singleLpa) }}</span>
                <i class="i-Business-ManWoman"/>
                <span>{{ currency(estatePlanningPrices.mirrorLpa) }}</span>
              </div>
            </div>
            <div class="back">
              <p>Keep the family home in the family with our effective lifetime protective trust options.
              </p>
              <h3>Click to Begin</h3>
            </div>
          </b-card>
        </b-col>


        <b-col md="3">
          <b-card body-class="pt-30 pl-30 pr-30 pb-20 d-flex flex-column justify-content-between"
                  class="hover-down p-0 dash-btn"
                  style="background: #321433"
                  @click="setProductModal(['custom'])"
          >
            <img alt="shield"
                 class="btn-background-img"
                 src="@/assets/images/start-3.png">
            <div class="face">
              <h3 class="text-white">Custom Package</h3>
            </div>
            <h3 class="back text-white">Click to Begin</h3>
          </b-card>
        </b-col>

        <!--        <b-col md="3">-->
        <!--          <b-card body-class="pt-30 pl-30 pr-30 pb-20 d-flex flex-column justify-content-between"-->
        <!--                  class="hover-down p-0 dash-btn"-->
        <!--                  style="background: #533d54"-->
        <!--                  @click="setProductModal(['will'], true)"-->
        <!--          >-->
        <!--            <img alt="shield"-->
        <!--                 class="btn-background-img"-->
        <!--                 src="@/assets/images/start-3.png">-->
        <!--            <div class="face">-->
        <!--              <h3 class="text-white">Send Will Instruction</h3>-->
        <!--            </div>-->
        <!--            <h3 class="back text-white">Click to Begin</h3>-->
        <!--          </b-card>-->
        <!--        </b-col>-->

        <b-col md="6">
          <b-card class="hover-down dash-btn"
                  @click="setProductModal(['estate_planning'])">
            <img alt="shield"
                 class="btn-background-img"
                 src="@/assets/images/start-3.png">
            <div class="face">
              <div class="face-top">
                <h3>Estate Planning Review</h3>
                <div class="d-flex">
                  <div>
                    <ul class="list-unstyled mt-20 mr-20">
                      <li class="tick-before success position-relative pl-30">
                        Will / Trust Analysis
                      </li>
                      <li class="tick-before success position-relative pl-30">
                        LPA Review
                      </li>
                    </ul>
                  </div>
                  <div>
                    <ul class="list-unstyled mt-20">
                      <li class="tick-before success position-relative pl-30">
                        IHT Calculation
                      </li>
                      <li class="tick-before success position-relative pl-30">
                        Written Recommendation
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="face-bottom">
                <i class="i-Tag"/>
                <span>Free Service</span>
              </div>
            </div>
            <div class="back">
              <p>Organise a detailed written recommendation that identifies and explains the key estate planning
                solutions for your client.
              </p>
              <h3>Click to Begin</h3>
            </div>
            <div class="ribbon success ribbon-top-right">
              <span>New</span>
            </div>
          </b-card>
        </b-col>

        <!--        <b-col md="6">-->
        <!--          <b-card class="hover-down dash-btn white"-->
        <!--                  style="background: #533d54"-->
        <!--                  @click="setProductModal(['estate_planning'])">-->
        <!--            <img alt="shield"-->
        <!--                 class="btn-background-img"-->
        <!--                 src="@/assets/images/start-3.png">-->
        <!--            <div class="face">-->
        <!--              <div class="face-top">-->
        <!--                <h3>Estate Administration (Probate)</h3>-->
        <!--                <h6>Instant Fixed Price Quote</h6>-->
        <!--              </div>-->
        <!--              <div class="face-bottom">-->
        <!--                <i class="i-Tag"/>-->
        <!--                <span>From £800</span>-->
        <!--              </div>-->
        <!--            </div>-->
        <!--            <div class="back">-->
        <!--              <p>Organise a detailed written recommendation that identifies and explains the key estate planning solutions for your client.-->
        <!--              </p>-->
        <!--              <h3>Click to Begin</h3>-->
        <!--            </div>-->
        <!--            <div class="ribbon success ribbon-top-right">-->
        <!--              <span>New</span>-->
        <!--            </div>-->
        <!--          </b-card>-->
        <!--        </b-col>-->
      </b-row>
    </b-collapse>

    <EstatePlanningModal
        v-model="show.productModal"
        :selectedProducts="show.products"
        :sendInstructions="show.sendInstructions"
        :prices="estatePlanningPrices"
        @refresh="$emit('refresh')"
        @refreshHandedOver="handoverComplete"
    />

    <BookingModal
        v-model="show.bookingModal"
        class="booking"
        :transaction="booking.transaction"
        :appointmentType="booking.appointmentType"
        :hostRole="booking.hostRole"
        :cancelAppointmentButton="true"
        :bookingInformationHTML="bookingInformationHTML"
        cancelText="Handover Without Booking Appointment"
        @cancel="handoverCompleteNoAppointment"
        @refresh="bookingComplete"
    />

  </div>
</template>

<script>
import {currencyFormat} from "@/mixins/currencyFormat";
import {productEstatePlanningServices} from "@/mixins/productEstatePlanningServices";
import { handoverHelpers } from "@/views/introducer/dashboard/createTransactions/handoverHelpers";

import EstatePlanningModal from "../modals/EstatePlanning.vue";
import BookingModal from "@/components/common/appointment/calender/BookingModal.vue";


export default {
  name: 'CreateEstatePlanningProducts',
  mixins: [productEstatePlanningServices, currencyFormat, handoverHelpers],
  components: {BookingModal, EstatePlanningModal},
  data() {
    return {
      show: {
        productModal: false,
        bookingModal: false,
        sendInstructions: false,
        products: []
      },
      booking: {
        appointmentType: 'Estate Planning Consultation', // default can change on different product creation or referrals
        hostRole: 'Estate Planning Introducer',
        transaction: null
      }
    }
  }
}
</script>
