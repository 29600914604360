<template>
  <div class="card card-transaction mb-30 o-hidden">
    <div class="card-body p-0 bg-gray-100">
      <div class="card-transaction-header bg-white border-bottom-gray-200 position-relative o-hidden">
        <img alt="shield"
             class="custom-background"
             src="@/assets/images/briefcase.svg">
        <div class="flex-nowrap d-flex justify-content-between">
          <div class="mb-20">
            <div class="d-flex justify-content-between">
              <h4 v-for="(client, index) in transaction.clients"
                  :key="`client${index}Transaction${transaction.id}`"
                  class="client-names-big mb-0">
                {{ clientNameFirstLast(client) }}
              </h4>
            </div>
            <b-badge>{{ type }}</b-badge>
          </div>
          <div class="text-right ml-auto cursor-pointer">
            <a @click="$router.push({name: 'IntroducerTransactionDetail', params: {id: transaction.id}})"> <i
                class="text-25 text-secondary i-Next1"></i> </a>
          </div>
        </div>
      </div>
      <Products v-if="showProducts" :transaction="transaction"/>

      <b-row class="pl-30 pr-30 pt-30">
        <b-col
            v-for="(event, index) in transaction.events"
            :key="`event${index}`"
            md="12">
          <AppointmentCard
              :event="event"
              :showReschedule="false"
          />
        </b-col>
      </b-row>

    </div>
  </div>
</template>

<script>
import {clientHelpers} from "@/mixins/clientHelpers";

import Products from "./Products";
import AppointmentCard from "@/components/common/appointment/AppointmentCard";

export default {
  name: 'TransactionCard',
  components: {AppointmentCard, Products},
  mixins: [clientHelpers],
  props: {
    transaction: {
      type: Object,
      required: true
    },
    showProducts: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    type () {
      switch (this.transaction.type) {
        case 'estate_planning':
          return 'Estate Planning'
        case 'mortgage':
          return 'Mortgage'
        case 'insurance':
          return 'Insurance'
        default:
          return 'NO TRANSACTION TYPE'
      }
    }
  }
}
</script>
