<template>
  <div v-if="mlw">
    <div class="section-title">
      <h4 class="p-3 pl-30 mb-2">
        <span>MyLastWill.co.uk</span>
      </h4>
      <a v-b-toggle.BtnMLW class="d-block">
        <i class="i-Arrow-Down-2 cursor-pointer text-25 t-font-boldest text-secondary"></i>
      </a>
      <a class="d-block">
        <i class="i-Information cursor-pointer text-25 text-secondary"></i>
      </a>
    </div>

    <b-collapse visible id="BtnMLW">
      <b-row class="pl-30 pl-md-48-md pr-20 pr-md-0 mb-30">
        <b-col md="9">
          <p>Built for mortgage and financial advisors, MyLastWill enables any firm to offer will writing services to
            their clients for a fixed monthly cost. To date over 40,000 clients have used the groundbreaking platform to
            create their Will. The service is entirely online (though telephone support is provided) and all wills are
            professionally checked.</p>
        </b-col>
      </b-row>
      <b-row class="pl-30 pl-md-48-md pr-20 pr-md-0">
        <b-col>
          <b-overlay
              :show="disabled"
              variant="transparent"
              opacity="0.5"

          >
            <b-card class="mb-48" style="background: #455a78" body-class="p-0">
              <div class="mlw-container" :class="{'sign-up-mode': startWillMode}">
                <div class="forms-container">
                  <div class="signin-signup">
                    <div class="mlw-form sign-in-form">
                      <b-row>
                        <b-col :cols="mirrorProduct ? 6 : 12">
                          <InputStandardBasic v-model="form.client_one.name" :args="{
                      label: mirrorProduct ? 'Client 1 Name' : 'Client Name',
                      inputClass  : 'text-white',
                      placeholder: 'e.g. Harriette Jones',
                      siz: 'lg'
                    }"
                                              :loading="loading"
                                              :errors="errors.client_one.name">
                            <template #prepend>
                        <span class="input-group-text "><i
                            class="i-Assistant"></i></span>
                            </template>
                          </InputStandardBasic>
                        </b-col>

                        <b-col v-if="mirrorProduct" colss="6">
                          <InputStandardBasic
                              v-model="form.client_two.name" :args="{
                      label:'Client 2 Name',
                      inputClass  : 'text-white',
                      placeholder: 'e.g. Harry Jones',
                      siz: 'lg'

                    }"
                              :loading="loading"
                              :errors="errors.client_two.name">
                            <template #prepend>
                        <span class="input-group-text"><i
                            class="i-Assistant"></i></span>
                            </template>
                          </InputStandardBasic>
                        </b-col>
                      </b-row>


                      <b-row>
                        <b-col :cols="mirrorProduct ? 6 : 12">

                          <InputStandardBasic v-model="form.client_one.phone" :args="{
                      label: mirrorProduct ? 'Client 1 Contact' : 'Contact Details',
                      inputClass  : 'text-white',
                      placeholder: mirrorProduct ? 'Mobile 1' : 'Client Mobile',
                      siz: 'lg'

                    }" :loading="loading" :errors="errors.client_one.phone">
                            <template #prepend>
                            <span class="input-group-text"><i
                                class="i-Monitor-Vertical"></i></span>
                            </template>
                          </InputStandardBasic>

                          <InputStandardBasic v-model="form.client_one.email" :args="{

                      inputClass  : 'text-white',
                      placeholder: mirrorProduct ? 'Email 1' : 'Client Email',
                      siz: 'lg',
                      type: 'email'

                    }" :errors="errors.client_one.email" :loading="loading">
                            <template #prepend>
                              <span class="input-group-text"><i class="i-Mail"></i></span>
                            </template>
                          </InputStandardBasic>

                        </b-col>

                        <b-col v-if="mirrorProduct" cols="6">
                          <InputStandardBasic v-model="form.client_two.phone" :args="{
                      label: mirrorProduct ? 'Client 2 Contact' : 'Contact Details',
                      inputClass  : 'text-white',
                      placeholder: mirrorProduct ? 'Mobile 2' : 'Client Mobile',
                      siz: 'lg'

                    }" :loading="loading" :errors="errors.client_two.phone">
                            <template #prepend>
                            <span class="input-group-text"><i
                                class="i-Monitor-Vertical"></i></span>
                            </template>
                          </InputStandardBasic>

                          <InputStandardBasic v-model="form.client_two.email" :args="{

                      inputClass  : 'text-white',
                      placeholder: mirrorProduct ? 'Email 2' : 'Client Email',
                      siz: 'lg',
                      type: 'email'

                    }" :loading="loading" :errors="errors.client_two.email">
                            <template #prepend>
                              <span class="input-group-text"><i class="i-Mail"></i></span>
                            </template>
                          </InputStandardBasic>
                        </b-col>
                      </b-row>

                      <div class="d-flex justify-content-between align-items-center">

                        <InputCheckBoxBasic
                            v-model="mirrorProduct"
                            :args="{label: 'Mirror Will', inputClass  : 'text-white'}"/>


                        <b-button :disabled="loading" @click="send" class="btn transparent">
                          Send Voucher
                          <b-spinner
                              v-if="loading"
                              class="small-spinner align-middle ml-1"/>
                        </b-button>

                      </div>


                    </div>

                    <div class="mlw-form sign-up-form">


                      <InputStandardBasic v-model="form.email" :args="{
                      label: 'Client Email',
                      inputClass  : 'text-white',
                      placeholder: 'example@me.com',
                      siz: 'lg'

                    }"
                                          :loading="loading"
                                          :errors="errors.name">
                        <template #prepend>
                          <span class="input-group-text"><i class="i-Mail"></i></span>
                        </template>
                      </InputStandardBasic>

                      <b-button
                          @click="start"
                          :disabled="loading"
                          class="btn transparent">
                        <b-spinner
                            v-if="loading"
                            class="small-spinner align-middle ml-1"/>
                        Start Will
                      </b-button>
                      <!--                    <small class="text-white mt-20"><strong>OR</strong> Click here to send a voucher instead</small>-->
                    </div>
                  </div>
                </div>

                <div class="panels-container">
                  <div class="panel left-panel">
                    <div class="content">
                      <h2 class="text-white mb-30">Send Will Voucher</h2>
                      <p class="text-white">
                        Complete the form to send a voucher to your client(s) so they can complete their Will. Once
                        they have answered the questions the will is checked and released within 72 hours.
                      </p>
                      <p class="text-white">
                        Alternatively <a @click="switchMode" class="cursor-pointer" id="sign-up-btn"><u>click
                        here</u></a>
                        to complete some of
                        the questions with your client before the voucher link is sent.
                      </p>
                    </div>
                    <img src="@/assets/images/mlw-logo.webp" class="image" alt=""/>
                  </div>

                  <div class="panel right-panel">
                    <div class="content">
                      <h2 class="text-white mb-30">Start Will (Handover Process)</h2>
                      <p class="text-white">
                        Complete some of the will questions with your client before a link is sent allowing them to
                        finish
                        the
                        process. This will take between 5 - 20 minutes depending on how many questions you complete
                        before
                        'handing over' the case.
                      </p>
                      <p class="text-white">
                        Alternatively, <a @click="switchMode" class="cursor-pointer" id="sign-in-btn"><u>click
                        here</u></a> to send a voucher
                        link to your client(s)
                        so they
                        can complete their Will.
                      </p>
                    </div>
                    <img src="@/assets/images/mlw-logo.webp" class="image" alt=""/>
                  </div>
                </div>
              </div>

              <div v-if="disabled" class="ribbon danger ribbon-top-right">
                <span>Disabled</span>
              </div>

              <template #footer>
                <template v-if="disabled">
                  <div class="d-flex">
                    <i class="i-Tag text-20 pl-2 pr-2 text-mute"
                    />
                    <span class="text-14">Subscription Required / Included With Subscription</span>
                  </div>
                  <div class="d-flex">
                    <span class="text-14">Subscribe (From £99 per month)</span>
                    <i class="i-Information text-20 pl-2 pr-2 text-secondary"
                    />
                  </div>
                </template>
              </template>
            </b-card>

            <template #overlay>-</template>
          </b-overlay>
        </b-col>
      </b-row>
    </b-collapse>
  </div>
</template>

<script>
import {http} from "@/services";
import {toast} from '@/mixins/toast'

import InputCheckBoxBasic from "@/components/common/other/inputs/InputCheckBoxBasic.vue";
import InputStandardBasic from "@/components/common/other/inputs/InputStandardBasic.vue";

export default {
  name: 'MlwSendCoupon',
  components: {InputStandardBasic, InputCheckBoxBasic},
  mixins: [toast],
  data() {
    return {
      startWillMode: false,
      form: {
        mirror: false,
        coupon_type: 'single',
        discount_service: null,
        discount_percentage: 100,
        client_one: {
          name: null,
          phone: null,
          email: null
        },
        client_two: {
          name: null,
          phone: null,
          email: null
        }
      },
      errors: {
        coupon_type: [],
        discount_service: [],
        discount_percentage: [],
        client_one: {
          name: [],
          phone: [],
          email: []
        },
        client_two: {
          name: [],
          phone: [],
          email: []
        }
      },
      loading: false
    }
  },
  methods: {
    switchMode() {
      this.startWillMode = !this.startWillMode
    },
    start() {
      // starts a will in handover mode
      this.loading = true
      this.clearErrors()
      http.post('mlw/start_will', this.form).then(
          () => {
            this.toast('Will Started')
            this.loading = false
            this.clearForm()
          }
      ).catch(
          error => {
            this.handleErrors(error.response.data)
            this.loading = false
          }
      )
    },
    send() {
      // send a voucher for client to start a will
      if (this.mirrorProduct) {
        this.form.discount_service = 'WILL-M'
      } else {
        this.form.discount_service = 'WILL-S'
      }

      this.loading = true
      this.clearErrors()
      http.post('mlw/send_coupon', this.form).then(
          () => {
            this.toast('Voucher sent')
            this.loading = false
            this.clearForm()
          }
      ).catch(
          error => {
            this.handleErrors(error.response.data)
            this.loading = false
          }
      )
    },
    clearForm() {
      this.form.coupon_type = 'single'
      this.form.discount_service = null
      this.form.discount_percentage = 100
      this.form.client_one = {
        name: null,
        phone: null,
        email: null
      }
      this.form.client_two = {
        name: null,
        phone: null,
        email: null
      }
      this.mirrorProduct = false
    },

    clearErrors() {
      this.errors.coupon_type = []
      this.errors.discount_service = []
      this.errors.discount_percentage = []
      this.errors.client_one = {
        name: [],
        phone: [],
        email: []
      }
      this.errors.client_two = {
        name: [],
        phone: [],
        email: []
      }
    },
    handleErrors(errors) {
      if (typeof errors === 'string') this.toastError(errors)
      else {
        let keys = Object.keys(errors);
        for (let i in keys) {
          if (keys[i] === 'client_one') {
            let clientOneKeys = Object.keys(errors.client_one)
            for (let i in clientOneKeys) {
              this.errors.client_one[clientOneKeys[i]] = errors.client_one[clientOneKeys[i]]
              this.toastError(`${errors.client_one[clientOneKeys[i]][0]}`, 'MLW Send Error - Client 1')

            }

          } else if (keys[i] === 'client_two') {
            let clientTwoKeys = Object.keys(errors.client_two)
            for (let i in clientTwoKeys) {
              this.errors.client_two[clientTwoKeys[i]] = errors.client_two[clientTwoKeys[i]]
              this.toastError(`${errors.client_two[clientTwoKeys[i]][0]}`,  'MLW Send Error - Client 2')
            }
          } else {
            this.errors[keys[i]] = errors[keys[i]]
          }
        }
      }

    }
  },
  computed: {
    disabled() {
      return false
    },
    user() {
      return this.$store.getters.user
    },
    introducer() {
      return this.user?.is_introducer
    },
    mlw() {
      // does the introducer have a mlw subscription
      return this.introducer?.mlw
    },
    mirrorProduct: {
      set(value) {
        value ? this.form.coupon_type = 'couple' : this.form.coupon_type = 'single'
      },
      get() {
        return this.form.coupon_type === 'couple'
      }
    }
  }
}

</script>
<style scoped>
.card-footer {
  border-radius: 0 0 10px 10px;
  display: flex;
  justify-content: space-between;
}


/* ANIMATION */

.mlw-container.sign-up-mode:before {
  transform: translate(100%, -50%);
  right: 52%;
}

.mlw-container.sign-up-mode .left-panel .image,
.mlw-container.sign-up-mode .left-panel .content {
  transform: translateX(-800px);
}

.mlw-container.sign-up-mode .signin-signup {
  left: 25%;
}

.mlw-container.sign-up-mode .mlw-form.sign-up-form {
  opacity: 1;
  z-index: 2;
}

.mlw-container.sign-up-mode .mlw-form.sign-in-form {
  opacity: 0;
  z-index: 1;
}

.mlw-container.sign-up-mode .right-panel .image,
.mlw-container.sign-up-mode .right-panel .content {
  transform: translateX(0%);
}

.mlw-container.sign-up-mode .left-panel {
  pointer-events: none;
}

.mlw-container.sign-up-mode .right-panel {
  pointer-events: all;
}

.small-spinner {
  width: 1rem;
  height: 1rem;
}
</style>