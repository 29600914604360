<template>
  <div>
    <div class="section-title">
      <h4 class="p-3 pl-30 mb-2">
        <span>Mortgage Advice</span>
      </h4>
      <a v-b-toggle.BtnM class="d-block">
        <i class="i-Arrow-Down-2 cursor-pointer text-25 t-font-boldest text-secondary"></i>
      </a>
      <a class="d-block">
        <i class="i-Information cursor-pointer text-25 text-secondary"></i>
      </a>
    </div>

    <b-collapse visible id="BtnM">
      <b-row class="pl-30 pl-md-48-md pr-20 pr-md-0 mb-30">
        <b-col md="9">
          <p>Led by some of the industries most experienced mortgage advisors, you can trust DM Mortgages to secure the
            best mortgage deals for your clients. Once a referral is made you can track the case from application to
            completion in the 'Referrals' tab.</p>
          <p>*Mortgage advice is free of charge. DM Financial Services charge a £495 administration fee on application
            for a purchase and £245 for a remortgage.</p>

          <!--          <p>Dunham McCarthy Financial Services Ltd is an Appointed Representative of PRIMIS Mortgage Network. PRIMIS-->
          <!--            Mortgage Network is a trading name of First Complete Ltd which is authorised and regulated by the Financial-->
          <!--            Conduct Authority for mortgages, protection insurance and general insurance products. Dunham McCarthy-->
          <!--            Financial Services Ltd is registered in England and Wales. Company No 7987241.-->

          <!--            The Financial Conduct Authority does not regulate all Buy to Let mortgages. Will writing services are-->
          <!--            referred to other Dunham McCarthy Group companies. Dunham McCarthy Financial Services nor PRIMIS Mortgage-->
          <!--            Network are responsible for the advice received. We do not charge a fee for mortgage advice. Any other fees-->
          <!--            applicable will be discussed and agreed with you at the earliest opportunity. The guidance and/or advice-->
          <!--            contained within this website is subject to the UK regulatory regime and is therefore primarily targeted at-->
          <!--            consumers based in the UK.</p>-->
        </b-col>
      </b-row>
      <b-row class="pl-30 pl-md-48-md pr-20 pr-md-0">
        <b-col md="3">
          <b-card class="bg-primary hover-down dash-btn white"
                  @click="createTransaction('Mortgage Sign Up')">
            <img alt="shield"
                 class="btn-background-img"
                 src="@/assets/images/start-4.png">
            <div class="face">
              <div class="face-top">
                <h3>Purchaser Referral</h3>
              </div>
              <div class="face-bottom">
                <i class="i-Tag"/>
                <span>Free Advice*</span>
              </div>
            </div>
            <div class="back">
              <p>Refer somebody interested in purchasing property for residential use or to let.
              </p>
              <h3>Click to Begin</h3>
            </div>
          </b-card>
        </b-col>

        <b-col md="3">
          <b-card class="hover-down dash-btn white"
                  style="background: #321433"
                  @click="createTransaction('Re-Mortgage Sign Up')">
            <img alt="shield"
                 class="btn-background-img"
                 src="@/assets/images/start-5.png">
            <div class="face">
              <div class="face-top">
                <h3>Remortgage Referral</h3>
              </div>
              <div class="face-bottom">
                <i class="i-Tag"/>
                <span>Free Advice*</span>
              </div>
            </div>
            <div class="back">
              <p>Refer somebody interested in remortgaging a property they currently own.
              </p>
              <h3>Click to Begin</h3>
            </div>
          </b-card>
        </b-col>

        <b-col md="6">
          <b-card class="hover-down dash-btn"
                  @click="createTransaction('Mortgage Offer Check')">
            <img alt="shield"
                 class="btn-background-img"
                 src="@/assets/images/start-4.png">
            <div class="face">
              <div class="face-top">
                <h3>Purchaser Offer Check</h3>
                <div class="d-flex">
                  <div>
                    <ul class="list-unstyled mt-20 mr-20">
                      <li class="tick-before success position-relative pl-30">
                        AIP Validation
                      </li>
                      <li class="tick-before success position-relative pl-30">
                        Deposit Check
                      </li>
                    </ul>
                  </div>
                  <div>
                    <ul class="list-unstyled mt-20">
                      <li class="tick-before success position-relative pl-30">
                        Mortgage Comparison
                      </li>
                      <li class="tick-before success position-relative pl-30">
                        ID Verification
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="face-bottom">
                <i class="i-Tag"/>
                <span>Free Priority Appointments</span>
              </div>
            </div>
            <div class="back">
              <p>Organise an affordability check for an applicant who has made an offer on a property.
              </p>
              <h3>Click to Begin</h3>
            </div>
            <div class="ribbon success ribbon-top-right">
              <span>Estate Agents</span>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-collapse>

    <MortgageModal
        v-model="show.productModal"
        :prices="estatePlanningPrices"
        @refresh="$emit('refresh')"
        @refreshHandedOver="handoverComplete"
    />

    <BookingModal
        v-model="show.bookingModal"
        class="booking"
        :transaction="booking.transaction"
        :appointmentType="booking.appointmentType"
        :hostRole="booking.hostRole"
        :cancelAppointmentButton="true"
        :bookingInformationHTML="bookingInformationHTML"
        cancelText="Handover Without Booking Appointment"
        @cancel="handoverCompleteNoAppointment"
        @refresh="bookingComplete"
    />

  </div>
</template>

<script>
import {handoverHelpers} from "@/views/introducer/dashboard/createTransactions/handoverHelpers";
import {currencyFormat} from "@/mixins/currencyFormat";
import {productEstatePlanningServices} from "@/mixins/productEstatePlanningServices";

import MortgageModal from "@/views/introducer/dashboard/modals/Mortgage.vue";
import BookingModal from "@/components/common/appointment/calender/BookingModal.vue";


export default {
  name: 'CreateMortgageProducts',
  mixins: [productEstatePlanningServices, currencyFormat, handoverHelpers],
  components: {BookingModal, MortgageModal},
  data() {
    return {
      show: {
        productModal: false,
        bookingModal: false
      },
      booking: {
        appointmentType: 'Mortgage Consultation', // default can change on different product creation or referrals
        hostRole: 'Mortgage Introducer',
        transaction: null
      }
    }
  }
}
</script>
