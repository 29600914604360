<template>
  <ul
      v-dragScroll.x="true"
      class="lineStage d-flex"
      :class="{ thin: statusThin }"
  >
    <li
        v-for="(option, index) in statusOptions"
        :key="index"
        :class="{
                'active Client1': option[0] === status,
                'active Client2': option[0] === statusMirror,
                inactive: option[0] !== statusMirror && option[0] !== status
            }"
        class="order-step"
    >
      <span class="">{{ option[1] }}</span>
      <span
          v-if="
                    option[0] === statusMirror &&
                    clientData &&
                    clientData.length === 2
                "
          class="badge badge-secondary ml-2"
      >{{ clientInitials(clientData[1]) }}</span
      >
      <span
          v-if="
                    option[0] === status &&
                    clientData &&
                    clientData.length === 2
                "
          class="badge badge-secondary ml-2"
      >{{ clientInitials(clientData[0]) }}</span
      >
    </li>
  </ul>
</template>

<script>
import { dragscroll } from 'vue-dragscroll';
import { clientHelpers } from '@/mixins/clientHelpers';

export default {
  name: 'Status',
  mixins: [clientHelpers],
  directives: {
    dragScroll: dragscroll
  },
  props: [
    'status',
    'statusMirror',
    'statusOptions',
    'statusThin',
    'clientData'
  ]
};
</script>
