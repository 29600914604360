<template>
  <div v-if="introducer" class="overflow-hidden">

    <Hero/>

    <Header/>

    <Welcome
        :introducer="introducer"
    />

    <Reviews class="bg-white"/>

    <div class="border-gray-200 border-wide-bottom border-wide-top">
      <b-container>
        <div class="border-wide-left border-gray-200 pt-48 pb-48">
          <template v-if="introducer.allowed_transaction_types">

            <ServicesList v-show="false" />

            <CreateMortgageProducts
                v-if="introducer.allowed_transaction_types.includes('mortgage')"
                @refresh="refreshRecentTransactions"
                @refreshHandedOver="refreshInProgressTransactions"
            />

            <CreateInsuranceProducts
                v-if="introducer.allowed_transaction_types.includes('insurance')"
                @refresh="refreshRecentTransactions"
                @refreshHandedOver="refreshInProgressTransactions"
            />


            <MlwSendCoupon
            />

            <CreateEstatePlanningProducts
                v-if="introducer.allowed_transaction_types.includes('estate_planning')"
                @refresh="refreshRecentTransactions"
                @refreshHandedOver="refreshInProgressTransactions"
            />


          </template>
        </div>
      </b-container>
    </div>

    <RecentTransactions
        ref="recentTransactions"/>

    <InProgressTransactions
        ref="inProgressTransactions"
    />
    <Help style="display: none"/>

    <Contact/>

    <div class="container">
      <Footer/>
    </div>
  </div>
</template>

<script>
import Footer from "@/components/common/questionnaires/questionnaire/Footer";
import CreateEstatePlanningProducts from "./createTransactions/CreateEstatePlanningProducts.vue";
import RecentTransactions from "./RecentTransactions";
import Help from "./Help";
import Header from "./Header";
import Hero from "./Hero";
import Contact from "../../../components/common/contact/Contact";
import CreateInsuranceProducts from "@/views/introducer/dashboard/createTransactions/CreateInsuranceProducts.vue";
import CreateMortgageProducts from "@/views/introducer/dashboard/createTransactions/CreateMortgageProducts.vue";
import InProgressTransactions from "@/views/introducer/dashboard/InProgressTransactions";
import Reviews from "@/components/common/other/Reviews";
import Welcome from "@/views/introducer/dashboard/Welcome.vue";
import ServicesList from "@/views/introducer/dashboard/ServicesList.vue";
import MlwSendCoupon from "@/views/introducer/dashboard/mlw/MlwSendCoupon.vue";

export default {
  name: 'Dashboard',
  components: {
    MlwSendCoupon,
    ServicesList,
    Welcome,
    Reviews,
    InProgressTransactions,
    CreateMortgageProducts,
    CreateInsuranceProducts,
    Contact,
    Hero,
    Header,
    Help,
    RecentTransactions,
    CreateEstatePlanningProducts,
    Footer
  },
  data() {
    return {
      slide: 0,
      sliding: null
    }
  },
  computed: {
    user() {
      return this.$store.getters.user
    },
    introducer() {
      return this.user?.is_introducer
    },
    mlw() {
      // does the introducer have a mlw subscription
      return this.introducer?.mlw
    },
    softwareDeveloper () {
      return this.user?.roles.includes('Software Developer')
    }
  },
  methods: {
    refreshRecentTransactions() {
      this.$refs.recentTransactions.fetch()
    },
    refreshInProgressTransactions() {
      this.$refs.inProgressTransactions.fetch()
    }
  }
}
</script>
