<template>
    <div class="hero-sub-bar-two-header border-bottom-gray-200 bg-white w-100 d-flex flex-column justify-content-center">
      <b-container>
        <div class="hero-sub-bar-two-heading d-flex text-16 font-weight-400">
          <div class="d-flex hero-sub-bar-two-heading-stat pr-30"><i
              class="pr-12 text-22 text-mute i-Business-Man"></i>
            {{ user.name || user.email }}
          </div>
          <div class="d-flex hero-sub-bar-two-heading-stat"><i
              class="pr-16 text-22 text-mute i-Business-Mens"></i>{{ introducer }}
          </div>
        </div>
      </b-container>
    </div>
</template>


<script>
export default {
  name: 'Header'
}
</script>

<script>
export default {
  name: 'Header',
  computed: {
    user() {
      return this.$store.getters.user
    },
    introducer() {
      if (this.user?.is_introducer) {
        return this.user.is_introducer.introducer
      }
      return 'NO INTRODUCER'
    },
  }
};
</script>