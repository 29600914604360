import {currencyFormat} from "@/mixins/currencyFormat";

export const insuranceOptions = {
    mixins: [currencyFormat],
    data() {
        return {
            reviewTypeOptions: [
                {
                    label: 'Single Person',
                    value: 'single'
                },
                {
                    label: 'Couple',
                    value: 'mirror'
                }
            ],
            handoverOptions: [
                {
                    label: 'Handover Immediately',
                    value: true
                },
                {
                    label: 'Add Information',
                    value: false
                }
            ]
        }
    }
}
