<template>
  <div>
    <div class="section-title">
      <h4 class="p-3 pl-30 mb-2">
        <span>Insurance Services</span>
      </h4>
      <a v-b-toggle.BtnI class="d-block">
        <i class="i-Arrow-Down-2 cursor-pointer text-25 t-font-boldest text-secondary"></i>
      </a>
      <a class="d-block">
        <i class="i-Information cursor-pointer text-25 text-secondary"></i>
      </a>
    </div>

    <b-collapse visible id="BtnI">
      <b-row class="pl-30 pl-md-48-md pr-20 pr-md-0 mb-30">
        <b-col md="9">
          <p>You won’t find a more knowledgable team of regulated insurance experts than those at Dunham McCarthy
            Financial Services. From buildings insurance to children’s critical illness, they’ve got it covered. Also,
            rest assured that all solutions are secured in a flexible discretionary trust without charge (where
            applicable). </p>
        </b-col>
      </b-row>
      <b-row class="pl-30 pl-md-48-md pr-20 pr-md-0">
        <b-col md="6">
          <b-card class="bg-primary hover-down dash-btn white"
                  @click="createTransaction(booking.appointmentType)">
            <img alt="shield"
                 class="btn-background-img"
                 src="@/assets/images/start-4.png">
            <div class="face">
              <div class="face-top">
                <h3>Full Insurance Review</h3>
                <h6>(Life / Critical Illness / Income Protection)</h6>
              </div>
              <div class="face-bottom">
                <i class="i-Tag"/>
                <span>Free Advice</span>
              </div>
            </div>
            <div class="back">
              <p>Refer a client who is interested in Life / Critical Illness Cover or Income Protection.
              </p>
              <h3>Click to Begin</h3>
            </div>
          </b-card>
        </b-col>

        <b-col md="3">
          <b-card class="hover-down dash-btn white"
                  style="background: #321433"
                  @click="createTransaction(booking.appointmentType)">
            <img alt="shield"
                 class="btn-background-img"
                 src="@/assets/images/start-4.png">
            <div class="face">
              <div class="face-top">
                <h3>Life / Critical Illness Referral</h3>
              </div>
              <div class="face-bottom">
                <i class="i-Tag"/>
                <span>Free Advice</span>
              </div>
            </div>
            <div class="back">
              <p>Refer a client who is interested in Life / Critical Illness Cover or Income Protection.
              </p>
              <h3>Click to Begin</h3>
            </div>
          </b-card>
        </b-col>

        <b-col md="3">
          <b-card class="hover-down dash-btn white"
                  style="background: #321433"
                  @click="createTransaction(booking.appointmentType)">
            <img alt="shield"
                 class="btn-background-img"
                 src="@/assets/images/start-4.png">
            <div class="face">
              <div class="face-top">
                <h3>Income Protection Referral</h3>
              </div>
              <div class="face-bottom">
                <i class="i-Tag"/>
                <span>Free Advice</span>
              </div>
            </div>
            <div class="back">
              <p>Refer a client who is interested in Life / Critical Illness Cover or Income Protection.
              </p>
              <h3>Click to Begin</h3>
            </div>
          </b-card>
        </b-col>

        <b-col md="3">
          <b-card class="hover-down dash-btn white"
                  style="background: #533d54; pointer-events: none"
                  @click="createTransaction(booking.appointmentType)">
            <img alt="shield"
                 class="btn-background-img"
                 src="@/assets/images/start-4.png">
            <div class="face">
              <div class="face-top">
                <h3>Private Medical Insurance</h3>
              </div>
              <div class="face-bottom">
                <i class="i-Tag"/>
                <span>Free Advice</span>
              </div>
            </div>
            <div class="back">
              <p>Refer a client who is interested in private medical insurance (PMI).
              </p>
              <h3>Click to Begin</h3>
            </div>
            <div class="ribbon success ribbon-top-right">
              <span>Coming Soon</span>
            </div>
          </b-card>
        </b-col>

        <b-col md="3">
          <b-card class="hover-down dash-btn white"
                  style="background: #533d54; pointer-events: none"
                  @click="createTransaction(booking.appointmentType)">
            <img alt="shield"
                 class="btn-background-img"
                 src="@/assets/images/start-4.png">
            <div class="face">
              <div class="face-top">
                <h3>Buildings / Contents Referral</h3>
              </div>
              <div class="face-bottom">
                <i class="i-Tag"/>
                <span>Free Advice</span>
              </div>
            </div>
            <div class="back">
              <p>Refer a client who is interested in general insurance.
              </p>
              <h3>Click to Begin</h3>
            </div>
            <div class="ribbon success ribbon-top-right">
              <span>Coming Soon</span>
            </div>
          </b-card>
        </b-col>

        <b-col md="6">
          <b-card class="hover-down dash-btn"
                  @click="createTransaction(booking.appointmentType)">
            <img alt="shield"
                 class="btn-background-img"
                 src="@/assets/images/start-4.png">
            <div class="face">
              <div class="face-top">
                <h3>Split / Discretionary Trusts<br>For Insurance Policies</h3>
              </div>
              <div class="face-bottom">
                <i class="i-Tag"/>
                <span>£199 (per policy)</span>
              </div>
            </div>
            <div class="back">
              <p>Refer a client who is interested in placing their insurance policy or policies in trust. Useful for
                those looking to reduce their IHT liability in certain situations.
              </p>
              <h3>Click to Begin</h3>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-collapse>

    <InsuranceModal
        v-model="show.productModal"
        :prices="estatePlanningPrices"
        @refresh="$emit('refresh')"
        @refreshHandedOver="handoverComplete"
    />

    <BookingModal
        v-model="show.bookingModal"
        class="booking"
        :transaction="booking.transaction"
        :appointmentType="booking.appointmentType"
        :hostRole="booking.hostRole"
        :cancelAppointmentButton="true"
        :bookingInformationHTML="bookingInformationHTML"
        cancelText="Handover Without Booking Appointment"
        @refresh="bookingComplete"
        @cancel="handoverCompleteNoAppointment"
    />

  </div>
</template>

<script>
import {handoverHelpers} from "@/views/introducer/dashboard/createTransactions/handoverHelpers";
import {currencyFormat} from "@/mixins/currencyFormat";
import {productEstatePlanningServices} from "@/mixins/productEstatePlanningServices";

import InsuranceModal from "@/views/introducer/dashboard/modals/Insurance.vue";
import BookingModal from "@/components/common/appointment/calender/BookingModal.vue";


export default {
  name: 'CreateInsuranceProducts',
  mixins: [productEstatePlanningServices, currencyFormat, handoverHelpers],
  components: {BookingModal, InsuranceModal},
  data() {
    return {
      show: {
        productModal: false,
        bookingModal: false
      },
      booking: {
        appointmentType: 'Insurance Consultation', // default can change on different product creation or referrals
        hostRole: 'Insurance Introducer',
        transaction: null
      }
    }
  }
}
</script>
