<template>
  <div class="pb-48 mt-20">
    <div class="container">
      <div class="section-title">
        <h4 class="p-3 mb-2">
          <span>Learning Center</span>
        </h4>
      </div>
      <b-row>
        <b-col lg="3">
          <b-card body-class="p-0"
                  class="o-hidden video-card mb-30">
            <div class="play-thumbnail aaa">
              <div
                  class="h-100 play-overlay d-flex flex-column text-center align-items-center justify-content-center">
                <i class="i-Play-Music text-white text-40"/>
                <span class=" text-20 text-white">Play</span>
              </div>
            </div>
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <h5 class="font-weight-bold">The Role of Executor Explained</h5>
                <i class="ml-2 text-mute text-25 font-weight-500 i-Heart"/></div>
              <h6 class="text-muted">Alex Clansey</h6>
            </div>
            <div class="card-footer bg-white">
              <b-row class="justify-content-between">
                <b-col class="d-flex align-items-center">
                  <i class="i-Stopwatch text-mute text-18 mr-1"/>
                  <div class="mb-0 text-mute text-12">4 Min</div>
                </b-col>
                <b-col class="d-flex align-items-center justify-content-end">
                  <i class="i-Ticket text-mute text-18 mr-1"/>
                  <div class="mb-0 text-mute text-12">Free</div>
                </b-col>
              </b-row>
            </div>
          </b-card>
        </b-col>
        <b-col lg="3">
          <b-card body-class="p-0"
                  class="o-hidden video-card mb-30">
            <div class="play-thumbnail bbb">
              <div
                  class="h-100 play-overlay d-flex flex-column text-center align-items-center justify-content-center">
                <i class="i-Play-Music text-white text-40"/>
                <span class=" text-20 text-white">Play</span>
              </div>
            </div>
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <h5 class="font-weight-bold">Protecting Assets</h5>
                <i class="ml-2 text-mute text-25 font-weight-500 i-Heart"/></div>
              <h6 class="text-muted">Alex Clansey</h6>
            </div>
            <div class="card-footer bg-white">
              <b-row class="justify-content-between">
                <b-col class="d-flex align-items-center">
                  <i class="i-Stopwatch text-mute text-18 mr-1"/>
                  <div class="mb-0 text-mute text-12">4 Min</div>
                </b-col>
                <b-col class="d-flex align-items-center justify-content-end">
                  <i class="i-Ticket text-mute text-18 mr-1"/>
                  <div class="mb-0 text-mute text-12">Free</div>
                </b-col>
              </b-row>
            </div>
          </b-card>
        </b-col>
        <b-col lg="3">
          <b-card body-class="p-0"
                  class="o-hidden video-card mb-30">
            <div class="play-thumbnail ccc">
              <div
                  class="h-100 play-overlay d-flex flex-column text-center align-items-center justify-content-center">
                <i class="i-Play-Music text-white text-40"/>
                <span class=" text-20 text-white">Play</span>
              </div>
            </div>
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <h5 class="font-weight-bold">Property Trusts Overview</h5>
                <i class="ml-2 text-mute text-25 font-weight-500 i-Heart"/></div>
              <h6 class="text-muted">Alex Clansey</h6>
            </div>
            <div class="card-footer bg-white">
              <b-row class="justify-content-between">
                <b-col class="d-flex align-items-center">
                  <i class="i-Stopwatch text-mute text-18 mr-1"/>
                  <div class="mb-0 text-mute text-12">4 Min</div>
                </b-col>
                <b-col class="d-flex align-items-center justify-content-end">
                  <i class="i-Ticket text-mute text-18 mr-1"/>
                  <div class="mb-0 text-mute text-12">Free</div>
                </b-col>
              </b-row>
            </div>
          </b-card>
        </b-col>
        <b-col lg="3">
          <b-card body-class="p-0"
                  class="o-hidden video-card mb-30">
            <div class="play-thumbnail ddd">
              <div
                  class="h-100 play-overlay d-flex flex-column text-center align-items-center justify-content-center">
                <i class="i-Play-Music text-white text-40"/>
                <span class=" text-20 text-white">Play</span>
              </div>
            </div>
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <h5 class="font-weight-bold">The Role of Trustee Explained</h5>
                <i class="ml-2 text-mute text-25 font-weight-500 i-Heart"/></div>
              <h6 class="text-muted">Alex Clansey</h6>
            </div>
            <div class="card-footer bg-white">
              <b-row class="justify-content-between">
                <b-col class="d-flex align-items-center">
                  <i class="i-Stopwatch text-mute text-18 mr-1"/>
                  <div class="mb-0 text-mute text-12">4 Min</div>
                </b-col>
                <b-col class="d-flex align-items-center justify-content-end">
                  <i class="i-Ticket text-mute text-18 mr-1"/>
                  <div class="mb-0 text-mute text-12">Free</div>
                </b-col>
              </b-row>
            </div>
          </b-card>
        </b-col>
        <b-col lg="3">
          <b-card body-class="p-0"
                  class="o-hidden video-card mb-30">
            <div class="play-thumbnail aaa">
              <div
                  class="h-100 play-overlay d-flex flex-column text-center align-items-center justify-content-center">
                <i class="i-Play-Music text-white text-40"/>
                <span class=" text-20 text-white">Play</span>
              </div>
            </div>
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <h5 class="font-weight-bold">The Role of Executor Explained</h5>
                <i class="ml-2 text-mute text-25 font-weight-500 i-Heart"/></div>
              <h6 class="text-muted">Alex Clansey</h6>
            </div>
            <div class="card-footer bg-white">
              <b-row class="justify-content-between">
                <b-col class="d-flex align-items-center">
                  <i class="i-Stopwatch text-mute text-18 mr-1"/>
                  <div class="mb-0 text-mute text-12">4 Min</div>
                </b-col>
                <b-col class="d-flex align-items-center justify-content-end">
                  <i class="i-Ticket text-mute text-18 mr-1"/>
                  <div class="mb-0 text-mute text-12">Free</div>
                </b-col>
              </b-row>
            </div>
          </b-card>
        </b-col>
        <b-col lg="3">
          <b-card body-class="p-0"
                  class="o-hidden video-card mb-30">
            <div class="play-thumbnail bbb">
              <div
                  class="h-100 play-overlay d-flex flex-column text-center align-items-center justify-content-center">
                <i class="i-Play-Music text-white text-40"/>
                <span class=" text-20 text-white">Play</span>
              </div>
            </div>
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <h5 class="font-weight-bold">Protecting Assets</h5>
                <i class="ml-2 text-mute text-25 font-weight-500 i-Heart"/></div>
              <h6 class="text-muted">Alex Clansey</h6>
            </div>
            <div class="card-footer bg-white">
              <b-row class="justify-content-between">
                <b-col class="d-flex align-items-center">
                  <i class="i-Stopwatch text-mute text-18 mr-1"/>
                  <div class="mb-0 text-mute text-12">4 Min</div>
                </b-col>
                <b-col class="d-flex align-items-center justify-content-end">
                  <i class="i-Ticket text-mute text-18 mr-1"/>
                  <div class="mb-0 text-mute text-12">Free</div>
                </b-col>
              </b-row>
            </div>
          </b-card>
        </b-col>
        <b-col lg="3">
          <b-card body-class="p-0"
                  class="o-hidden video-card mb-30">
            <div class="play-thumbnail ccc">
              <div
                  class="h-100 play-overlay d-flex flex-column text-center align-items-center justify-content-center">
                <i class="i-Play-Music text-white text-40"/>
                <span class=" text-20 text-white">Play</span>
              </div>
            </div>
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <h5 class="font-weight-bold">Property Trusts Overview</h5>
                <i class="ml-2 text-mute text-25 font-weight-500 i-Heart"/></div>
              <h6 class="text-muted">Alex Clansey</h6>
            </div>
            <div class="card-footer bg-white">
              <b-row class="justify-content-between">
                <b-col class="d-flex align-items-center">
                  <i class="i-Stopwatch text-mute text-18 mr-1"/>
                  <div class="mb-0 text-mute text-12">4 Min</div>
                </b-col>
                <b-col class="d-flex align-items-center justify-content-end">
                  <i class="i-Ticket text-mute text-18 mr-1"/>
                  <div class="mb-0 text-mute text-12">Free</div>
                </b-col>
              </b-row>
            </div>
          </b-card>
        </b-col>
        <b-col lg="3">
          <b-card body-class="p-0"
                  class="o-hidden video-card mb-30">
            <div class="play-thumbnail ddd">
              <div
                  class="h-100 play-overlay d-flex flex-column text-center align-items-center justify-content-center">
                <i class="i-Play-Music text-white text-40"/>
                <span class=" text-20 text-white">Play</span>
              </div>
            </div>
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <h5 class="font-weight-bold">The Role of Trustee Explained</h5>
                <i class="ml-2 text-mute text-25 font-weight-500 i-Heart"/></div>
              <h6 class="text-muted">Alex Clansey</h6>
            </div>
            <div class="card-footer bg-white">
              <b-row class="justify-content-between">
                <b-col class="d-flex align-items-center">
                  <i class="i-Stopwatch text-mute text-18 mr-1"/>
                  <div class="mb-0 text-mute text-12">4 Min</div>
                </b-col>
                <b-col class="d-flex align-items-center justify-content-end">
                  <i class="i-Ticket text-mute text-18 mr-1"/>
                  <div class="mb-0 text-mute text-12">Free</div>
                </b-col>
              </b-row>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Help'
}
</script>