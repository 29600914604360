<template>
  <b-container class="pt-80 position-relative" style="padding-bottom: 130px">
    <img alt="welcome"
         class="bg-welcome"
         src="@/assets/images/Welcome.png">
    <b-row>
      <b-col lg="7">
        <div class="border-extra-wide-left border-secondary pl-40"><h4 class="mb-30">Welcome</h4>

          <p v-if="introducer.allowed_transaction_types.includes('estate_planning' && ('mortgage' || 'insurance'))">
            Welcome to 'Kernel', the cutting edge platform designed to allow you to refer, track and manage your
            clients as they progress through various transactions with the legal and financial advisors at the Dunham McCarthy
            Group. The system also allows you to access the online will writing services of MyLastWill (subscription
            required).
          </p>
          <p v-else-if="introducer.allowed_transaction_types.includes('estate_planning')">
            Welcome to 'Kernel', the cutting edge platform designed to allow you to refer, track and manage your
            clients as they progress through various transactions with the legal advisors at the Dunham McCarthy
            Group. The system also allows you to access the online will writing services of MyLastWill (subscription
            required).
          </p>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
export default {
  name: 'Welcome',
  props: {
    introducer: {
      type: Object,
      required: true
    }
  }
}
</script>