var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{directives:[{name:"dragScroll",rawName:"v-dragScroll.x",value:(true),expression:"true",modifiers:{"x":true}}],staticClass:"lineStage d-flex",class:{ thin: _vm.statusThin }},_vm._l((_vm.statusOptions),function(option,index){return _c('li',{key:index,staticClass:"order-step",class:{
              'active Client1': option[0] === _vm.status,
              'active Client2': option[0] === _vm.statusMirror,
              inactive: option[0] !== _vm.statusMirror && option[0] !== _vm.status
          }},[_c('span',{},[_vm._v(_vm._s(option[1]))]),(
                  option[0] === _vm.statusMirror &&
                  _vm.clientData &&
                  _vm.clientData.length === 2
              )?_c('span',{staticClass:"badge badge-secondary ml-2"},[_vm._v(_vm._s(_vm.clientInitials(_vm.clientData[1])))]):_vm._e(),(
                  option[0] === _vm.status &&
                  _vm.clientData &&
                  _vm.clientData.length === 2
              )?_c('span',{staticClass:"badge badge-secondary ml-2"},[_vm._v(_vm._s(_vm.clientInitials(_vm.clientData[0])))]):_vm._e()])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }