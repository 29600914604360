<template>

  <div class="pb-40">
    <div class="container">
      <div class="section-title">
        <h4 class="p-3 mb-2">
          <span>Recent Referrals (Pending Handover to DM)</span>
        </h4>
      </div>

      <b-alert v-if="results.length"
               class="mb-48"
               show
               variant="alert alert-card alert-warning text-center">
        <p class="pt-16 pb-6">
          Below are referrals that have been created but an appointment has not yet been booked with the team at Dunham McCarthy.
        </p>
      </b-alert>

      <b-overlay
          :show="show.loading"
          rounded="sm"
          spinner-type="border"
          spinner-variant="primary"
      >
        <b-card-group columns cols="6">
          <div v-for="(transaction, index) in results" :key="`transaction${index}`">
            <TransactionCard
                :transaction="transaction"
                :show-products="true"
            />
          </div>
        </b-card-group>
      </b-overlay>

      <CardPagination
          :current-page="currentPage"
          :total-pages="totalPages"
          :results="results"
          :page-size="pageSize"
          :total-rows="totalRows"
          size="md"
          no-results-message="There are no referrals that have yet to be handed over to Dunham McCarthy."
          @currentPageChange="currentPage=$event"
          @pageSizeChange="pageSize=$event"
          @fetch="fetch"
      />

    </div>
  </div>
</template>

<script>
import {http} from "@/services";

import TransactionCard from "./transaction/TransactionCard";
import CardPagination from "@/components/common/other/CardPagination";

export default {
  name: 'RecentTransactions',
  components: {CardPagination, TransactionCard},
  mounted() {
    this.fetch()
  },
  data() {
    return {
      results: [],
      currentPage: 1,
      totalPages: 0,
      totalRows: 0,
      pageSize: 4,
      show: {
        loading: true
      }
    }
  },
  methods: {
    fetch() {
      console.log('fetch recent transactions')
      // fetches results
      let params = {
        page: this.currentPage,
        page_size: this.pageSize
      }
      this.show.loading = true

      http.get('transaction_recent_list', {params: params}).then(
          response => {
            this.results = response.data.results
            this.currentPage = response.data.current_page_number
            this.totalPages = response.data.total_pages
            this.totalRows = response.data.total_objects
            this.pageSize = response.data.page_size
            this.show.loading = false
          }
      ).catch(
          error => {
            console.log(error)
            this.show.loading = false
          }
      )
    },
    changePage(event) {
      // changes page
      this.currentPage = event
      this.fetch()
    }
  }
}
</script>
