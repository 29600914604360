<template>
  <div
      class="card-transaction-product-outer card-style"
  >
    <router-link
        :to="{name: 'IntroducerTransactionDetail', params: { id: transactionId }}"
        class="card-transaction-product"
    >
      <div
          class="card-transaction-product-header align-item-center flex-nowrap"
      >
        <div>
          <h3 class="ul-widget__head-title mb-1">
            {{ product.label }}
          </h3>

          <small v-if="product.data.client" class="client-names">
            {{ clientNameShort(product.data.client) }}
          </small>
          <template v-else-if="product.data.clients">
            <small
                v-for="(client, index) in product.data.clients"
                :key="index"
                class="client-names"
            >
              {{ clientNameShort(client) }}
            </small>
          </template>
          <small class="text-muted ml-8"
          >Created:
            <span class="font-weight-500">{{
                formatDate(product.data.created_date)
              }}</span>
          </small>

        </div>

        <div class="text-right ml-auto">
          <span><i class="text-25 text-secondary i-Next1"></i></span>
        </div>
      </div>
      <Status
          :status="product.data.status"
          :statusOptions="product.data.STATUS_CHOICES"
      />
    </router-link>
  </div>
</template>

<script>
import {dateFormat} from '@/mixins/dateFormat';
import {clientHelpers} from '@/mixins/clientHelpers';

import Status from './Status';

export default {
  name: 'ProductCard',
  props: {
    product: {
      type: Object,
      required: true
    },
    transactionId: {
      type: Number,
      required: true
    }
  },
  mixins: [dateFormat, clientHelpers],
  components: {
    Status
  },
  computed: {
    getProductUrl() {
      let url = '';
      if (this.product.type === 'will') url = 'will';
      else if (this.product.type === 'lpa') url = 'lpa';
      else if (this.product.type === 'policy') url = 'policy';
      else if (this.product.type === 'storage') url = 'storage';
      else if (this.product.type === 'severance') url = 'severance';
      else if (this.product.type === 'expression') url = 'expression';
      return url;
    }
  }
};
</script>
