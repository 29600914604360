import { currencyFormat } from "@/mixins/currencyFormat";

export const estatePlanningOptions = {
    mixins: [currencyFormat],
    data() {
        return {
            reviewTypeOptions: [
                {
                    label: 'Single Person',
                    value: 'single'
                },
                {
                    label: 'Couple',
                    value: 'mirror'
                }
            ],
            willTypeNoPricesOptions: [
                {
                    label: `Single Will`,
                    value: 'single'
                },
                {
                    label: `2x Mirror Wills`,
                    value: 'mirror'
                }
            ],
            handoverOptions: [
                {
                    label: 'Handover Immediately',
                    value: true
                },
                {
                    label: 'Add Information',
                    value: false
                }
            ]
        }
    },
    computed: {
        productOptions () {
            return [
                {
                    label: 'Will',
                    value: 'will'
                },
                {
                    label: 'LPA',
                    value: 'lpa'
                },
                {
                    label: `Protective Property Trust`,
                    value: 'property_trust'
                },
                {
                    label: 'Estate Planning Review',
                    value: 'estate_planning'
                }
            ]
        },
        willTypeOptions () {
            return [
                {
                    label: `Single Will (${this.currency(this.prices.singleWill)})`,
                    value: 'single'
                },
                {
                    label: `2x Mirror Wills (${this.currency(this.prices.mirrorWill)})`,
                    value: 'mirror'
                }
            ]
        },
        lpaTypeOptions () {
            return [
                {
                    label: `Single Lpa (${this.currency(this.prices.singleLpa)})`,
                    value: 'single'
                },
                {
                    label: `2x Mirror LPA's (${this.currency(this.prices.mirrorLpa)})`,
                    value: 'mirror'
                }
            ]
        },
        propertyTrustTypeOptions () {
            return [
                {
                    label: `Protective property trust (${this.currency(this.prices.ppt)})`,
                    value: 'mirror'
                }
            ]
        },

    }
}
