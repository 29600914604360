<template>
  <div>
    <div style="display: none" class="section-title">
      <h4 class="p-3 pl-30 mb-2">
        <span>Introducer Services Index</span>
      </h4>
    </div>
    <div class="pl-20">
      <b-row>
        <b-col md="3" class="mb-30">
          <h5 class="ml-12">
            Estate Planning
          </h5>
          <ul class="list-unstyled mt-20">
            <li class="chevron-before success position-relative pl-30">
              Last Will & Testament
            </li>
            <li class="chevron-before success position-relative pl-30">
              Protective Property Will's
            </li>
            <li class="chevron-before success position-relative pl-30">
              Will & Disabled Persons Trust
            </li>
            <li class="chevron-before success position-relative pl-30">
              Will & Discretionary Trust
            </li>
            <li class="chevron-before success position-relative pl-30">
              Estate Planning Review
            </li>
            <li class="chevron-before success position-relative pl-30">
              Estate Administration
            </li>
          </ul>
        </b-col>
        <b-col md="3" class="mb-30">
          <h5 class="ml-12">
            Power of Attorney
          </h5>
          <ul class="list-unstyled mt-20">
            <li class="chevron-before success position-relative pl-30">
              Lasting Power of Attorney
            </li>
            <li class="chevron-before success position-relative pl-30">
              General Power of Attorney
            </li>
            <li class="chevron-before success position-relative pl-30">
              Advanced Directive
            </li>
          </ul>
        </b-col>
        <b-col md="3" class="mb-30">
          <h5 class="ml-12">
            Mortgage Advice
          </h5>
          <ul class="list-unstyled mt-20">
            <li class="chevron-before success position-relative pl-30">
              Purchaser Mortgage
            </li>
            <li class="chevron-before success position-relative pl-30">
              Home-Mover Mortgage
            </li>
            <li class="chevron-before success position-relative pl-30">
              Buy-to-Let Mortgage
            </li>
            <li class="chevron-before success position-relative pl-30">
              Re-Mortgage
            </li>
            <li class="chevron-before success position-relative pl-30">
              Purchaser Offer Check
            </li>
          </ul>
        </b-col>
        <b-col md="3" class="mb-30">
          <h5 class="ml-12">
            Insurance Services
          </h5>
          <ul class="list-unstyled mt-20">
            <li class="chevron-before success position-relative pl-30">
              Life Insurance / Critical Illness
            </li>
            <li class="chevron-before success position-relative pl-30">
              Income Protection
            </li>
            <li class="chevron-before success position-relative pl-30">
              Private Medical Insurance
            </li>
            <li class="chevron-before success position-relative pl-30">
              Buildings & Contents
            </li>
            <li class="chevron-before success position-relative pl-30">
              Split / Discretionary Trusts
            </li>
            <li class="chevron-before success position-relative pl-30">
              Business Protection
            </li>
          </ul>
        </b-col>
        <b-col md="3" class="mb-30">
          <h5 class="ml-12">
            Property Services
          </h5>
          <ul class="list-unstyled mt-20">
            <li class="chevron-before success position-relative pl-30">
              Declaration of Trust
            </li>
            <li class="chevron-before success position-relative pl-30">
              Transfer of Equity
            </li>
            <li class="chevron-before success position-relative pl-30">
              Severance of Tenancy
            </li>
          </ul>
        </b-col>
        <b-col md="3" class="mb-30">
          <h5 class="ml-12">
            Asset Protection
          </h5>
          <ul class="list-unstyled mt-20">
            <li class="chevron-before success position-relative pl-30">
              Home Protection Trust
            </li>
            <li class="chevron-before success position-relative pl-30">
              Asset Protection Trust
            </li>
          </ul>
        </b-col>
        <b-col md="3" class="mb-30">
          <h5 class="ml-12">
            Family Law
          </h5>
          <ul class="list-unstyled mt-20">
            <li class="chevron-before success position-relative pl-30">
              Pre-nuptial Agreement
            </li>
            <li class="chevron-before success position-relative pl-30">
              Post-nuptial Agreement
            </li>
            <li class="chevron-before success position-relative pl-30">
              Divorce / Dissolution
            </li>
            <li class="chevron-before success position-relative pl-30">
              Cohabitation Agreements
            </li>
            <li class="chevron-before success position-relative pl-30">
              Legal Separation
            </li>
          </ul>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ServicesList'
}
</script>